<template>
  <router-link :to="to" tag="button" class="button button-text">{{
    name
  }}</router-link>
</template>

<script>
module.exports = {
  props: ["name", "to"],
};
</script>
