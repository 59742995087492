const Airtable = require("airtable");
const base = new Airtable({ apiKey: "keywRmwJEofgnhPXa" }).base(
  "appnsmlyii4OyJo89"
);
export default {
  methods: {
    rand() {
      return Math.random().toString(36).substr(2);
    },
    token() {
      return this.rand() + this.rand();
    },
    createSession() {
      const vm = this;
      return base("Session").create({
        Team: vm.team,
        Fullname: vm.fullname,
        Date: new Date(),
        Score: vm.score,
      });
    },

    fetchSession(session) {
      return base("Session")
        .select({
          filterByFormula: `{Token} = "${session}"`,
        })
        .firstPage()
        .catch(function (err) {
          if (err) {
            console.error(err);
            return;
          }
        });
    },
  },
};
