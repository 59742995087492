import Vue from "vue";
import App from "./App.vue";
import router from "./routes";
import "./components";
import VueAnime from "../node_modules/vue-animejs";

import "./styles/main.scss";

Vue.use(require("vue-cookies"));
Vue.$cookies.config("7d");
Vue.config.productionTip = false;
Vue.use(VueAnime);

window._ = require("lodash");

Vue.mixin({
  methods: {
    imageproxy(img_path, options) {
      if (process.env.NO_IMAGEPROXY) return img_path;
      const url =
        process.env.VUE_APP_IMAGEPROXY +
        "/" +
        options +
        "/" +
        window.location.origin +
        img_path;
      // console.log(url)
      return url;
    },
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

var isChromium = window.chrome;
var winNav = window.navigator;
var vendorName = winNav.vendor;
var isOpera = typeof window.opr !== "undefined";
var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
var isIOSChrome = winNav.userAgent.match("CriOS");

if (isIOSChrome) {
  console.log("IOS Chrome");
} else if (
  isChromium !== null &&
  typeof isChromium !== "undefined" &&
  vendorName === "Google Inc." &&
  isOpera === false &&
  isIEedge === false
) {
  console.log("This is Chrome");
  document.body.classList.add("is-chrome");
} else {
  console.log("Not Chrome");
}
