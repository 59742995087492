<template>
  <div class="prior" @click="click">
    <hi-image-prior :image="images[0]" />
    <div class="img-wrapper">
      <h2>Question {{ questionNb }}</h2>
    </div>
    <hi-image-prior :image="images[1]" />
    <hi-image-prior :image="images[2]" />
  </div>
</template>

<script>
import bus from "./../bus";
export default {
  props: ["quiz", "images"],

  methods: {
    click() {
      bus.$emit("priorIsOver");
    },
  },
  computed: {
    questionNb: function () {
      return this.quiz + 1;
    },
  },
  mounted() {
    setTimeout(function () {
      bus.$emit("priorIsOver");
    }, 6000);
  },
};
</script>
