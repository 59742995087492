<template>
  <div class="content-wrapper">
    <div class="banner banner-up">
      <img class="ofi-image" src="/assets/static/banner_paysage.jpg" />
    </div>

      <div class="content">
        <h1>
          Bienvenue sur l’Exploquiz&nbsp;!
        </h1>
        <p>
          Tu vas pouvoir tester tes connaissances des énergies au fil des 10 questions concoctées par les Explorateurs. Ce quiz est élaboré à partir de 100 questions choisies au hasard. Tu peux donc le refaire plusieurs fois et il sera toujours différent&nbsp;!
        </p>
        <br>
        <p>
          Lis attentivement les questions et trouve la bonne réponse parmi les trois propositions.
       Bonne chance&nbsp;:)</p>
        <hi-button-text :to="'/Team/'+team" name="Départ"></hi-button-text>
      </div>
      <router-view />

  </div>
</template>

<script>
  import bus from "./../bus";


  export default {
    data() {
      return {
        team: 'Grand Jeu des énergies',
        teamClass: 'generic'
      };
    },
    mounted() {

      bus.$emit("teamChanged", { team: this.team, teamClass: this.teamClass });
    }
  };
</script>
