export const Teams = [
  "Sherpas",
  "Farfouilleurs",
  "Éclaireurs",
  "Stratèges",
  "Guetteurs",
  "Pisteurs",
  "Trappeurs",
  "Messagers",
  "Ravitailleurs",
  "Pionniers",
  "Hawaii",
  "Visiteurs",
  "Grand Jeu des énergies"
];

export const Themes = {
  Solaire: "solar",
  Géothermique: "geothermal",
  Nucléaire: "nuclear",
  Fossiles: "fossil",
  Biomasse: "biomass",
  Éolien: "wind",
  Éolienne: "wind",
  Marines: "marine",
  Hydraulique: "hydraulic",
  Musculaire: "muscle",
  Général: "general",
  "Éco-gestes": "ecogesture",
};

export const QuizMax = 10;
