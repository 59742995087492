<template>
  <div class="intermediar" @click="click">
    <div class="media-wrapper">
      <template>
        <iframe v-if="intermediar_type=='video'" :src="formattedUrl()" width="650" height="auto" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        <img v-else alt="" :src="imageproxy('/' + image_path, '1180x')" />
      </template>
    </div>
    <div class="legend">
      <span>{{ consigne }}</span>
      <a @click="click" class="btn">Passer à l'étape suivante</a>
    </div>
  </div>
</template>

<script>
import bus from "./../bus";
export default {
  props: [ "intermediar_type", "consigne", "url", "image_path"],
  methods: {
    click() {
      bus.$emit("intermediarIsOver");
    },
    formattedUrl() {
      // if this.url contains "?"
      if (this.url.indexOf("?") >= 0) {
        return this.url + "&title=0&byline=0&portrait=0&share=0";
      } else {
        // return this.url + "?autoplay=1"
        return this.url;
      }
    },
  },
};
</script>
