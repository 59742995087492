<template>
  <div class="field">
    <hi-label labelFor="name"> {{ label }} </hi-label>

    <div
      :class="{
        control: true,
        'has-icons-left': icon,
        'has-icons-right': errors.has(name),
      }"
    >
      <input
        :type="type"
        :id="name"
        :name="name"
        :placeholder="placeholder"
        v-model="form[name]"
        :class="{
          input: true,
          'is-danger': errors.has(name),
          'is-rounded': rounded,
        }"
      />
      <span v-if="icon" class="icon is-small is-left">
        <i :class="icon"></i>
      </span>
      <span v-if="errors.has(name)" class="icon is-small is-right">
        <i class="fas fa-exclamation-triangle"></i>
      </span>
      <slot></slot>
      <span
        class="help is-danger"
        v-if="errors.has(name)"
        v-text="errors.get(name)"
      ></span>
    </div>
  </div>
</template>

<script>
import ControlMixin from "./../../mixins/ControlMixin";
export default {
  mixins: [ControlMixin],
  props: ["name", "type", "label", "placeholder", "icon", "size", "rounded"],
};
</script>
