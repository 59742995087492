import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import Home from "./pages/Home";
import Welcome from "./pages/Welcome";
import Team from "./pages/Team";
import Question from "./pages/Question";
import Answer from "./pages/Answer";
import Final from "./pages/Final";

const routes = [
  // {
  //     path: '*',
  //     component: NotFound
  // },

  {
    path: "/",
    component: Home,
  },
  {
    path: "/welcome/:team",
    component: Welcome,
    name: "Welcome",
  },
  {
    path: "/team/:team",
    component: Team,
    name: "Team",
  },
  {
    path: "/question",
    component: Question,
    name: "Question",
  },
  {
    path: "/question/:quiz",
    component: Question,
    props: true,
  },
  {
    path: "/answer/:truefalse",
    component: Answer,
    name: "Answer",
  },

  {
    path: "/final",
    component: Final,
    name: "Final",
  },
  {
    path: "/explorateurs",
    beforeEnter() {
      location.href = "https://explorateurs-energie.com/";
    },
    name: "Explorateurs",
  },
];

export default new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "is-active",
});
