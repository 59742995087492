import Questions from "./../questions";


export default {
  methods: {
    rand() {
      return Math.random().toString(36).substr(2);
    },
    token() {
      return this.rand() + this.rand();
    },
    createSession() {
      const session = this.token();
      const vm = this;
      return new Promise((resolve) => {
        vm.$cookies.set("ee_session", {
          Fullname: this.form.fullname,
          Date: new Date(),
          Score: 0,
          State: "Playing",
          Quiz: 0,
          Token: session,
          Answered: 0,
          Questions: vm.randomQuestions(),
        });
        resolve();
      });
    },

    fetchSession() {
      let session = this.$cookies.get("ee_session");
      return session;
    },

    setQuiz(quiz) {
      let session = this.$cookies.get("ee_session");
      this.$cookies.set("ee_session", session);
    },

    // Fetch all distinct themes from Questions.js
    fetchThemes() {

      let themes = [];
      const allQuestions = Object.values(Questions);
      allQuestions.forEach((question) => {
        if (!themes.includes(question["Theme"])) {
          themes.push(question["Theme"]);
        }
      });

      // shuffle themes
      themes.sort(() => Math.random() - 0.5);
      // return first 10 themes
      themes = themes.slice(0, 10);

      return themes;
    },

    // select 10 random questions from Questions.js
    // constraint: 1 question per theme (there are 11 themes)
    randomQuestions() {
      // get Questions keys
      
      let questions = [];
      let themes = this.fetchThemes();

      let random = 0;
      let question = null;
      // for each theme
      themes.forEach((theme) => {
        // get all questions for this theme
        let themeQuestions = Object.values(Questions).filter((question) => {
          return question.Theme === theme;
        });
        // select a random question
        random = Math.floor(Math.random() * 10);

        question = themeQuestions[random];
        // add question to questions
        questions.push(question["Quiz"]);
      });
      return JSON.stringify(questions);
    },

    // Get questions from session
    getQuestions() {
      let session = this.$cookies.get("ee_session");
      return session["Questions"];
    },
  },
};
