<template>
  <div class="content-wrapper">
    <div class="banner banner-up">
      <img class="ofi-image" src="/assets/static/banner_paysage.jpg" />
    </div>
    <template v-if="found">
      <div class="content content-form">
        <hi-team-flag :team="team" />
        <h1>Inscris-toi&nbsp;!</h1>
        <div class="form-container">
          <hi-form :form="form" @submit="submit">
            <hi-input
              name="fullname"
              type="text"
              label="Inscris ton prénom ou un pseudo"
              placeholder="Harry Potter"
            ></hi-input>
            
            <button class="button button-text" :disabled="form.name == ''">
              C'est parti&nbsp;!
            </button>
          </hi-form>
        </div>
      </div>
    </template>
    <template v-else>Pas d'équipe trouvée</template>
  </div>
</template>

<script>
import HIForm from "./../objects/HIForm";
import TeamMixin from "./../mixins/TeamMixin";
import SessionMixin from "./../mixins/SessionCookieMixin";
import bus from "./../bus";
import { Teams } from "./../data";
export default {
  mixins: [TeamMixin, SessionMixin],
  data() {
    return {
      form: new HIForm({}),
      found: false,
      team: "",
      teamClass: "",
      link_to: "#",
      name: "Commence le Quiz!",
    };
  },

  methods: {
    submit() {
      let vm = this;
      this.createSession()
        .then(() => vm.$router.push({ name: "Question" }))
        .catch((err) => console.log(err));
    },
  },

  mounted() {
    window.history.forward(1)
    const team = this.$route.params.team;
    const teamClass = "team" + String(Teams.indexOf(team) + 1);
    bus.$emit("teamChanged", { team: team, teamClass: teamClass });
    this.teamClass = teamClass;
    this.team = team;
  },
};
</script>

<style lang="scss" scoped></style>
