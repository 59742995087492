<template>
  <form
    class="form"
    :form="form"
    @submit.prevent="submit"
    @keydown="clearError"
    @change="clearError"
  >
    <slot></slot>
  </form>
</template>

<script>
export default {
  props: { form: {} },
  methods: {
    clearError(event) {
      this.form.errors.clear(event.target.name);
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
