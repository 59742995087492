<template>
  <div id="app">
    <hi-container>
      <router-view></router-view>
    </hi-container>
  </div>
</template>

<script>
import bus from "./bus";
export default {
  name: "App",
  data() {
    return {
      // Default values
      team: "Hawaii",
      teamClass: "team0",
      theme: "Start",
      themeClass: "start",
    };
  },
  created() {
    bus.$on("teamChanged", ({ team, teamClass }) => {
      this.team = team;
      this.teamClass = teamClass;
    });

    bus.$on("themeChanged", ({ theme, themeClass }) => {
      this.theme = theme;
      this.themeClass = themeClass;
    });
  },
};
</script>

<style lang="scss">
//  <transition name="fade" mode="out-in">
</style>
