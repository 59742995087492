<template>
  <div>
    <template v-if="prior">
      <hi-prior :quiz="quiz" :images="images"></hi-prior>
    </template>
    <template v-else>
      <div class="content-wrapper">
        <div class="question-number">Question {{ questionNb }} / {{ quizMax }}</div>
        <template v-if="intermediar">
          <hi-intermediar
            :quiz="quiz"
            :intermediar_type="intermediar_type"
            :consigne="intermediarConsigne"
            :url="intermediarURL"
            :image_path="intermediarImagePath"
          ></hi-intermediar>
        </template>
        <template v-else>
          <hi-question-wrapper
            :answers="answers"
            :quiz="quiz"
            :answered="answered"
            :question="question"
          ></hi-question-wrapper>
        </template>
        <!-- Intermediar -->
      </div>
    </template>
    <!-- Prior -->
  </div>
</template>

<script>
import HIForm from "./../objects/HIForm.js";
import SessionMixin from "./../mixins/SessionCookieMixin";
import bus from "./../bus";
import {  Themes, QuizMax } from "./../data";
import Questions from "./../questions";
import Answers from "./../answers";

export default {
  mixins: [SessionMixin],
  data() {
    return {
      form: new HIForm({
        answer: "",
      }),
      prior: true,
      quiz: null,
      question: "",
      questions: [],
      answers: [],
      session_id: null,
      score: 0,
      answered: false,
      theme: "",
      themeClass: "",
      intermediar: false,
      intermediar_type: "",
      intermediarConsigne: "",
      intermediarURL: "",
      intermediarImagePath: "",
      images: [],
      quizMax: QuizMax,
    };
  },

  computed: {
    questionNb: function() {
      return this.quiz + 1;
    }
  },

  mounted() {
    window.history.forward(1) // Disable back button
    bus.$on("priorIsOver", () => {
      this.prior = false;
    });

    bus.$on("intermediarIsOver", () => {
      this.intermediar = false;
    });

    const vm = this;

    // Fetch session from cookies
    const record = this.fetchSession();
    // Get current quiz number
    if(this.$route.params.quiz > 0) {
      record["Quiz"] = this.$route.params.quiz;
      this.setQuiz(record["Quiz"]);
    }
    const quiz =  record["Quiz"];
    // const team = record["Team"];
    // const teamClass = "team" + String(Teams.indexOf(team) + 1);
    // bus.$emit("teamChanged", { team: team, teamClass: teamClass });
    if (parseInt(quiz)+1 > parseInt(QuizMax)) {
      vm.$router.push({ name: "Final" });
      return;
    }
    vm.score = record["Score"];
    vm.quiz = quiz;
    vm.answered = record["Answered"] == 1;
    const questions = JSON.parse(record["Questions"]);
    const quizID = questions[quiz];
    console.log("quizID", quizID);
    const question = Object.values(Questions).filter(
      (q) => q["Quiz"] == quizID
    )[0];
    console.log("question", question);  
    const quest = question["Question"];
    const theme = question["Theme"];
    const themeClass = Themes[theme];
    bus.$emit("themeChanged", {
      theme: theme,
      themeClass: themeClass,
    });
    vm.question = quest;
    vm.theme = theme;
    vm.themeClass = themeClass;
    vm.intermediar_type = question["Intermediar"];
    vm.intermediar =
      typeof question["Intermediar"] == "undefined" ? false : true;
    vm.intermediarConsigne = question["ConsigneIntermediar"];
    vm.intermediarURL = question["ConsigneURL"];
    vm.intermediarImagePath = question["ConsigneImagePath"];
    vm.images = question["Images"];
    let allAnswers = Object.values(Answers);
    // flatten array
    allAnswers = [].concat.apply([], allAnswers);
    // Fetch question's answers
    const responses = allAnswers.filter(
      (a) => a["Quiz"] == quizID
    );
    responses.forEach(function(response, idx) {
      let correct = response["Vrai/faux"].trim() == "VRAI" ? true : false;
      console.log(response, correct);
      vm.$set(vm.answers, idx, {
        id: response["id"],
        title: response["Title"],
        correct_answer: correct,
      });

      // if (response["Vrai/faux"] == "Vrai") {
      //   vm.correct_answer = response["Id"];
      // }
    });

  }, // end mounted
}; // end export
</script>
