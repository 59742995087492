<template>
  <label :for="label">
    <input type="radio" :id="label" :value="label" v-model="form[name]" />
    {{ label }}
  </label>
</template>

<script>
import ControlMixin from "./../../mixins/ControlMixin";
export default {
  mixins: [ControlMixin],
  props: ["name", "label"],
};
</script>
