<template>
  <div class="question-wrapper">
    <h1 class="my-anim" ref="title">Question {{ questionNb }}</h1>
    <p v-if="answered">(Vous avez déjà répondu à cette question)</p>
    <p class="my-anim" ref="text" v-html="question"></p>
    <div class="button-wrapper">
      <hi-button-answer
        v-for="(answer, key) in answers"
        :key="answer.id"
        :data-index="key"
        :correct_answer="answer.correct_answer"
        :delay="key"
      >
        <span v-html="answer.title"></span>
      </hi-button-answer>
    </div>
  </div>
</template>

<script>
import Anime from "animejs/lib/anime.es.js";

export default {
  props: ["answers", "correct_answer", "quiz", "answered", "question"],
  methods: {
    runAnimation() {
      let btns = Array.from(document.querySelectorAll(".my-anim"));
      Anime(
        {
          targets: btns,
          translateY: [-60, 0],
          opacity: [0, 1],
          duration: 1000,
          delay: Anime.stagger(200),
        },
        "-=1000"
      );
    },
  },
  mounted() {
    this.runAnimation();
  },
  computed: {
    questionNb: function () {
      return this.quiz + 1;
    },
  }
};
</script>
