import Vue from "vue";
import HILabel from "./components/form/HILabel";
import HIInput from "./components/form/HIInput";
import HIRadio from "./components/form/HIRadio";
import HISelect from "./components/form/HISelect";
import HITextArea from "./components/form/HITextArea";
import HIForm from "./components/form/HIForm";
import Header from "./components/Header";
import Prior from "./components/Prior";
import Intermediar from "./components/Intermediar";
import ButtonText from "./components/ButtonText";
import ButtonAnswer from "./components/ButtonAnswer";
import TeamFlag from "./components/TeamFlag";
import BannerIcons from "./components/BannerIcons";
import Container from "./components/Container";
import ImagePrior from "./components/ImagePrior";
import QuestionWrapper from "./components/QuestionWrapper";

Vue.component("hi-label", HILabel);
Vue.component("hi-input", HIInput);
Vue.component("hi-radio", HIRadio);
Vue.component("hi-select", HISelect);
Vue.component("hi-form", HIForm);
Vue.component("hi-textarea", HITextArea);
Vue.component("hi-header", Header);
Vue.component("hi-prior", Prior);
Vue.component("hi-intermediar", Intermediar);
Vue.component("hi-button-text", ButtonText);
Vue.component("hi-button-answer", ButtonAnswer);
Vue.component("hi-team-flag", TeamFlag);
Vue.component("hi-banner-icons", BannerIcons);
Vue.component("hi-container", Container);
Vue.component("hi-image-prior", ImagePrior);
Vue.component("hi-question-wrapper", QuestionWrapper)

//
// SVG COMPONENTS
//

// THEMES
import IconBiomass from "./assets/icon-biomass.svg";
import IconEco from "./assets/icon-ecogesture.svg";
import IconGeo from "./assets/icon-geothermal.svg";
import IconFossil from "./assets/icon-fossil.svg";
import IconGeneral from "./assets/icon-general.svg";
import IconHydro from "./assets/icon-hydraulic.svg";
import IconMuscle from "./assets/icon-muscle.svg";
import IconNuclear from "./assets/icon-nuclear.svg";
import IconSea from "./assets/icon-sea.svg";
import IconSun from "./assets/icon-sun.svg";
import IconWind from "./assets/icon-wind.svg";

Vue.component("icon-biomass", IconBiomass);
Vue.component("icon-ecogesture", IconEco);
Vue.component("icon-geothermal", IconGeo);
Vue.component("icon-fossil", IconFossil);
Vue.component("icon-general", IconGeneral);
Vue.component("icon-hydraulic", IconHydro);
Vue.component("icon-muscle", IconMuscle);
Vue.component("icon-nuclear", IconNuclear);
Vue.component("icon-sea", IconSea);
Vue.component("icon-sun", IconSun);
Vue.component("icon-wind", IconWind);

// TEAMS
import IconPionniers from "./assets/icon-pionniers.svg";
import IconRavitailleurs from "./assets/icon-ravitailleurs.svg";
import IconFarfouilleurs from "./assets/icon-farfouilleurs.svg";
import IconMessagers from "./assets/icon-messagers.svg";
import IconStrateges from "./assets/icon-strateges.svg";
import IconTrappeurs from "./assets/icon-trappeurs.svg";
import IconSherpas from "./assets/icon-sherpas.svg";
import IconGuetteurs from "./assets/icon-guetteurs.svg";
import IconPisteurs from "./assets/icon-pisteurs.svg";
import IconEclaireurs from "./assets/icon-eclaireurs.svg";

Vue.component("icon-pionniers", IconPionniers);
Vue.component("icon-ravitailleurs", IconRavitailleurs);
Vue.component("icon-farfouilleurs", IconFarfouilleurs);
Vue.component("icon-messagers", IconMessagers);
Vue.component("icon-strateges", IconStrateges);
Vue.component("icon-trappeurs", IconTrappeurs);
Vue.component("icon-sherpas", IconSherpas);
Vue.component("icon-guetteurs", IconGuetteurs);
Vue.component("icon-pisteurs", IconPisteurs);
Vue.component("icon-eclaireurs", IconEclaireurs);
