<template>
  <div class="field">
    <hi-label labelFor="name"> {{ label }} </hi-label>
    <div class="control">
      <div class="select">
        <select :name="name" v-bind="$attrs" v-model="form[name]">
          <slot />
          <option value="" selected>{{ placeholder }}</option>
          <template v-for="(options, group) in groupedOptions">
            <optgroup :label="group" v-if="group" :key="group">
              <option
                v-for="option in options"
                v-bind="attrsFor(option)"
                :key="attrsFor(option)"
                >{{ labelFor(option) }}
              </option>
            </optgroup>
            <template v-else>
              <option
                v-for="option in options"
                v-bind="attrsFor(option)"
                :key="attrsFor(option)"
              >
                {{ labelFor(option) }}
              </option>
            </template>
          </template>
        </select>
      </div>
    </div>
    <span
      class="help is-danger"
      v-if="errors.has(name)"
      v-text="errors.get(name)"
    ></span>
  </div>
</template>

<script>
import ControlMixin from "./../../mixins/ControlMixin";
const _ = require("lodash");
export default {
  mixins: [ControlMixin],
  props: {
    options: {},
    selected: {},
    label: {
      default: "label",
    },
    value: {},
    name: {},
    prompt: {},
  },

  computed: {
    placeholder() {
      return this.prompt || "Choisissez une option:";
    },

    groupedOptions() {
      return _.groupBy(this.options, (option) => option.group || "");
    },

    changeListeners: function () {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        change: function (event) {
          vm.$emit("change", event);
        },
      });
    },
  },
  methods: {
    labelFor(option) {
      return option.label;
    },

    attrsFor(option) {
      return _.assign(
        {},
        option.attrs,
        { value: option.value },
        this.selected !== void 0
          ? { selected: this.selected == option.value }
          : {}
      );
    },
  },
  mounted() {
    this.form.load(this.name);
  },
};
</script>
