<template>
  <div class="field">
    <hi-label labelFor="name"> {{ label }} </hi-label>

    <div :class="{ control: true }">
      <textarea
        :id="name"
        :name="name"
        :placeholder="placeholder"
        v-model="form[name]"
        :rows="rows"
        :class="{ textarea: true, 'is-danger': errors.has(name) }"
      >

        <slot></slot>
        </textarea
      >
      <span
        class="help is-danger"
        v-if="errors.has(name)"
        v-text="errors.get(name)"
      ></span>
    </div>
  </div>
</template>

<script>
import ControlMixin from "./../../mixins/ControlMixin";

export default {
  mixins: [ControlMixin],
  props: { name: {}, label: {}, placeholder: {}, rows: { default: 4 } },
};
</script>
