<template>
  <div class="content-wrapper">
    <div class="banner banner-up">
      <img class="ofi-image" src="/assets/static/banner_paysage.jpg" />
    </div>
    <template v-if="found">
      <div class="content">
        <h1>
          Bienvenue sur l’Exploquiz&nbsp;!
        </h1>
        <p>
          Tu vas pouvoir tester tes connaissances des énergies au fil des vingt questions que t’ont concoctées les Explorateurs. Lis attentivement les questions et trouve la bonne réponse parmi les trois propositions. 
        </p>
        <p>Bonne chance&nbsp;!</p>
        <hi-button-text :to="link_to" :name="name"></hi-button-text>
      </div>
      <router-view />
    </template>
    <template v-else>Pas d'équipe trouvée</template>
  </div>
</template>

<script>
import HIForm from "./../objects/HIForm";
import TeamMixin from "./../mixins/TeamMixin";

export default {
  mixins: [TeamMixin],
  data() {
    return {
      form: new HIForm({}),
      found: false,
      link_to: "/Team/" + this.$route.params.team,
      name: "Départ",
    };
  },
  methods: {},

  mounted() {
    this.$cookies.remove("ee_session");
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
