<template>
  <button
    type="button"
    :class="[{ active: isActive }, 'button', 'button-answer', 'my-anim']"
    @click="click"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ["correct_answer", "idx", "delay"],
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    click() {
      this.isActive = true;
      const vm = this;
      let session = this.$cookies.get("ee_session");
      const score = parseInt(session["Score"]);
      setTimeout(function () {
        // let truefalse = e.target.innerText.trim() == vm.correct_answer.trim();
        let truefalse = vm.correct_answer
        let newScore = truefalse ? score + 1 : score;
        if (String(session["Answered"]) == "0") {
          session["Score"] = newScore;
        }
        session["Answered"] = 1;
        vm.$cookies.set("ee_session", session);
        vm.$router.push({
          name: "Answer",
          params: { truefalse: `${truefalse}` },
        });
      }, 1000);
    },
  },

};
</script>

<style lang="scss"></style>
