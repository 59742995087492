export default {
  computed: {
    form() {
      return this.$parent.form;
    },
    errors() {
      return this.$parent.form.errors;
    },
  },
  mounted() {
    this.form.load(this.name);
  },
};
