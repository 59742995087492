<template>
  <label :for="labelFor" class="label">
    <slot />
  </label>
</template>

<script>
export default {
  props: ["labelFor"],
};
</script>
