export default {
  1: [
    {
      Id: "recJtIotVSeRL4III",
      Title: "Une centrale de production de gaz naturel ",
      Quiz: 1,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recZGBiO9xnw2eRuj",
      Title: "Une centrale de biomasse sèche ",
      Quiz: 1,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recqc5JGO8GkBnotz",
      Title: "Une centrale de biomasse humide",
      Quiz: 1,
      "Vrai/faux": "FAUX",
    },
  ],
  2: [
    {
      Id: "recwBhCQEQIJrJPPd",
      Title: "Un digesteur",
      Quiz: 2,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rechqih5mEsO6x1fq",
      Title: "Une turbine",
      Quiz: 2,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rechkEzpCb9SpFypO",
      Title: "Une cuve hermétique",
      Quiz: 2,
      "Vrai/faux": "FAUX",
    },
  ],
  3: [
    {
      Id: "recPx9AOp33X76U3n",
      Title: "Parce que c'est une énergie bon marché.",
      Quiz: 3,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec8yfFxoj5p54UFb",
      Title: "Parce que c'est une énergie non renouvelable.",
      Quiz: 3,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recgurRPHzPQZJYyz",
      Title: "Parce que c'est une énergie respectueuse de l'environnement.",
      Quiz: 3,
      "Vrai/faux": "VRAI ",
    },
  ],
  4: [
    {
      Id: "recEpFcWJHuBIde6g",
      Title: "… est une nouvelle technologie que l'on doit encore affiner.",
      Quiz: 4,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recyMTZpdJmpYzRq8",
      Title: "… est générée par le mouvement des océans.",
      Quiz: 4,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reczBpOsoorWfcOOf",
      Title:
        "… permet de produire de l'électricité, de la chaleur et du carburant.",
      Quiz: 4,
      "Vrai/faux": "VRAI ",
    },
  ],
  5: [
    {
      Id: "recZN8xWdMzlRJ5hb",
      Title: "… de la fonte des neiges.",
      Quiz: 5,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recOBQU0CKwvYM0v9",
      Title: "… des végétaux et des animaux.",
      Quiz: 5,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recAPVprTj89z7bwK",
      Title: "… des minéraux.",
      Quiz: 5,
      "Vrai/faux": "FAUX",
    },
  ],
  6: [
    {
      Id: "recf8FqUmg7Nk8gSB",
      Title:
        "...pour faire chauffer de l'eau dans une centrale électrique afin que cette dernière se transforme en vapeur. La vapeur fait tourner une turbine, ce qui produit de l'électricité.",
      Quiz: 6,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rec0CJi0cCWpca7yV",
      Title:
        "...pour en extraire de l'huile qui est ensuite transformée et raffinée pour être utilisée dans un moteur diesel.",
      Quiz: 6,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recZTlccLIhdDR58S",
      Title:
        "...pour le laisser fermenter dans un digesteur afin de produire du biogaz.",
      Quiz: 6,
      "Vrai/faux": "FAUX",
    },
  ],
  7: [
    {
      Id: "recgBvpUBEEeId509",
      Title: "Environ 2'000 ans",
      Quiz: 7,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recCi0dbI5xTas1fC",
      Title: "Environ 300'000 ans",
      Quiz: 7,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recEJ7764fbeSrGh0",
      Title: "Environ 600'000 ans",
      Quiz: 7,
      "Vrai/faux": "FAUX",
    },
  ],
  8: [
    {
      Id: "recMqGvRYQ826i4EV",
      Title: "Une essence 10x plus performante que les autres",
      Quiz: 8,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec43qF17UrezRwOP",
      Title: "Une essence avec 10 composantes",
      Quiz: 8,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recnCs19GYgmnG793",
      Title: "Une essence avec 10% d'éthanol",
      Quiz: 8,
      "Vrai/faux": "VRAI ",
    },
  ],
  9: [
    {
      Id: "recHYUtHTkxHnmMqX",
      Title: "Matières métalliques",
      Quiz: 9,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec3P0qebbmXt3wSu",
      Title: "Matières plastiques",
      Quiz: 9,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recwkpMyN7UuFWsMZ",
      Title: "Matières organiques",
      Quiz: 9,
      "Vrai/faux": "VRAI ",
    },
  ],
  10: [
    { Id: "rec0wlfqTgdFCPRHK", Title: "5%", Quiz: 10, "Vrai/faux": "FAUX" },
    { Id: "recinrmEuhtWGhVia", Title: "10%", Quiz: 10, "Vrai/faux": "VRAI " },
    { Id: "recOdumpjNuMnv8x9", Title: "20%", Quiz: 10, "Vrai/faux": "FAUX" },
  ],
  11: [
    {
      Id: "rec9Jz6SvmGAu07T6",
      Title: "… réduire notre consommation de viande. ",
      Quiz: 11,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rec55XrvBjXKKMY4R",
      Title: "… manger uniquement des aliments crus.",
      Quiz: 11,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec5N0W6hxMBjC1lb",
      Title: "… privilégier les restaurants plutôt que cuisiner chez soi.",
      Quiz: 11,
      "Vrai/faux": "FAUX",
    },
  ],
  12: [
    {
      Id: "recxig0lqaBpIJruk",
      Title: "... fermer les volets et la porte à clé.",
      Quiz: 12,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recD8j8Wip1X0C2uY",
      Title:
        "... baisser le chauffage et éteindre complètement tous les appareils électriques.",
      Quiz: 12,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recZYPwut1D3wdeuo",
      Title: "... demander au voisin de s'occuper de mes plantes.",
      Quiz: 12,
      "Vrai/faux": "FAUX",
    },
  ],
  13: [
    {
      Id: "recQ9qV5KJduddwwh",
      Title:
        "Ce sont des gestes simples qui favorisent la protection de l'environnement et qui sont à la portée de tout le monde.",
      Quiz: 13,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recxGdOr3cenjoLmh",
      Title: "Ce sont des gestes simples pour économiser de l'argent.",
      Quiz: 13,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recOl1W8nm9VY7Axn",
      Title: "Ce sont des gestes de la langue des signes.",
      Quiz: 13,
      "Vrai/faux": "FAUX",
    },
  ],
  14: [
    {
      Id: "reca8pTFqYPEcU0Be",
      Title:
        "Prendre l'avion; privilégier la marche; utiliser sa voiture toujours seul·e.",
      Quiz: 14,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recwVPxMNepxit6ej",
      Title:
        "Plutôt douche que bain; laver le linge à température modérée; sécher le linge à l'air libre.",
      Quiz: 14,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rec9WgBVMryn0yfnn",
      Title:
        "Manger des légumes locaux; cuisiner sans couvercle; régler son frigo sur 5°C.",
      Quiz: 14,
      "Vrai/faux": "FAUX",
    },
  ],
  15: [
    {
      Id: "rec6qSA00veIyzqtX",
      Title: "23-24°C",
      Quiz: 15,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recdLneOziGuRsCZ9",
      Title: "21-22°C",
      Quiz: 15,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recjBfp6YjYRG2QdB",
      Title: "19-20°C",
      Quiz: 15,
      "Vrai/faux": "VRAI ",
    },
  ],
  16: [
    {
      Id: "recvIMaHCGfE9dUXo",
      Title:
        "Ils n'ont presque pas d'influence, car c'est l'industrie qui pollue le plus.",
      Quiz: 16,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recAvvChcGumCkbfr",
      Title:
        "Les éco-gestes permettent d'économiser de l'argent, mais n'influencent pas le climat.",
      Quiz: 16,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recoWM5Mzh9Zafbwe",
      Title:
        "Des chercheurs ont démontré que les éco-gestes peuvent avoir un impact sur la protection du climat. ",
      Quiz: 16,
      "Vrai/faux": "VRAI ",
    },
  ],
  17: [
    { Id: "recHgsCRW8hebnwlj", Title: "1%", Quiz: 17, "Vrai/faux": "FAUX" },
    { Id: "recZrN6WUPmSq3Sjx", Title: "7%", Quiz: 17, "Vrai/faux": "VRAI " },
    { Id: "recq2GuxL18HCJ2nz", Title: "9%", Quiz: 17, "Vrai/faux": "FAUX" },
  ],
  18: [
    {
      Id: "rech8q1qX9Zndxg8Z",
      Title: "Allumer un humidificateur.",
      Quiz: 18,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recdPQvYyByUmYtxg",
      Title: "Augmenter le chauffage.",
      Quiz: 18,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recy8x6DvH2NPVFjJ",
      Title: "Fermer les stores.",
      Quiz: 18,
      "Vrai/faux": "VRAI ",
    },
  ],
  19: [
    {
      Id: "reckm6lq0jYVvWmYi",
      Title: "C'est un bon désherbant.\n",
      Quiz: 19,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recmQZp5cKUxorCFK",
      Title: "Il apporte du calcium.",
      Quiz: 19,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recxVdlpB2otLX0gA",
      Title: "C'est un parfait engrais.",
      Quiz: 19,
      "Vrai/faux": "FAUX",
    },
  ],
  20: [
    {
      Id: "recx3Fa90LLPhjzkM",
      Title:
        "Je laisse l'eau du robinet couler en attendant que la température me convienne et prends des bains.\n",
      Quiz: 20,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recW030cZRtSIH1Bj",
      Title:
        "J'utilise le lave-vaisselle uniquement quand il est plein et je prends des douches.",
      Quiz: 20,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recBjD2GCwOcFWqLz",
      Title:
        "Je fais ma vaisselle à la main, je ne bois que de l'eau en bouteille de marque suisse.",
      Quiz: 20,
      "Vrai/faux": "FAUX",
    },
  ],
  21: [
    {
      Id: "rec8phQfzVbNfOoQc",
      Title: "Produire de l'électricité.",
      Quiz: 21,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recfQoEwUScN7WHri",
      Title: "Moudre du grain et pomper de l'eau.",
      Quiz: 21,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recPcuJ1IDy5eMGNL",
      Title: "Générer de l'air frais pour le bétail.",
      Quiz: 21,
      "Vrai/faux": "FAUX",
    },
  ],
  22: [
    {
      Id: "recOZfBi7jSDmpW0N",
      Title:
        "Dans les villes, pour que l'électricité produite se trouve directement au bon endroit.",
      Quiz: 22,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recV1Y3s0kFnyBPC5",
      Title:
        "À proximité des rivières, car c'est là qu'il y a le plus de vent.",
      Quiz: 22,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recyCSC9bH65BOHdv",
      Title:
        "Sur un terrain élevé et dégagé pour bénéficier de grands vents soufflant sans obstacles et de façon régulière.",
      Quiz: 22,
      "Vrai/faux": "VRAI ",
    },
  ],
  23: [
    {
      Id: "recUr4A8UeRThVk2y",
      Title: "... 140 foyers",
      Quiz: 23,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recvgLFhnzKPKRGGR",
      Title: "... 1'400 foyers",
      Quiz: 23,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recFuqOp9EYrvtDmo",
      Title: "… 14'000 foyers",
      Quiz: 23,
      "Vrai/faux": "FAUX",
    },
  ],
  24: [
    {
      Id: "rec6bbOT0YrmfMcfB",
      Title: " 25 voitures électriques",
      Quiz: 24,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec6lrvAa0a3ZdppQ",
      Title: "2'500  voitures électriques",
      Quiz: 24,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recdCTM4qTve2hD4f",
      Title: " 25'000 voitures électriques",
      Quiz: 24,
      "Vrai/faux": "FAUX",
    },
  ],
  25: [
    {
      Id: "recH7M4fAq3LS9IPn",
      Title: "… 1 semaine",
      Quiz: 25,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recIRosk8c4QAz741",
      Title: "… 1 mois",
      Quiz: 25,
      "Vrai/faux": "VRAI ",
    },
    { Id: "recJpxioDIxsxlTMl", Title: "… 1 an", Quiz: 25, "Vrai/faux": "FAUX" },
  ],
  26: [
    {
      Id: "recaqm1ROhThfcueB",
      Title: "Parce que le froid fait tourner plus rapidement les éoliennes.",
      Quiz: 26,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recYYh37Nr44ceCPL",
      Title:
        "Parce qu'il fait nuit plus tôt et les éoliennes fonctionnent mieux dans l'obscurité.",
      Quiz: 26,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recoFue3BAbabiB01",
      Title:
        "Parce qu'une éolienne génère deux tiers de sa production annuelle à cette saison.",
      Quiz: 26,
      "Vrai/faux": "VRAI ",
    },
  ],
  27: [
    {
      Id: "recTD1MLJS701wU66",
      Title: "Ébiselé",
      Quiz: 27,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recmFdr5JopNDSvRK",
      Title: "Aéromoteur",
      Quiz: 27,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recAgGBNkIrLEgKCE",
      Title: "Aérogénérateur",
      Quiz: 27,
      "Vrai/faux": "VRAI ",
    },
  ],
  28: [
    {
      Id: "rec5EOKrEdCvUuI6w",
      Title: "Sainte-Croix",
      Quiz: 28,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recpNy1X2eNujIDHa",
      Title: "Mollendruz",
      Quiz: 28,
      "Vrai/faux": "FAUX",
    },
    { Id: "recB4jBuxvMLUDOHg", Title: "Lucens", Quiz: 28, "Vrai/faux": "FAUX" },
  ],
  29: [
    { Id: "recKunKIIrOrPLw5x", Title: "10 ans", Quiz: 29, "Vrai/faux": "FAUX" },
    { Id: "rec5qOrsGU7CE5Dfz", Title: "15 ans", Quiz: 29, "Vrai/faux": "FAUX" },
    {
      Id: "recyMJ5mJ7IqqHOGJ",
      Title: "25 ans",
      Quiz: 29,
      "Vrai/faux": "VRAI ",
    },
  ],
  30: [
    {
      Id: "rec6XqdtbZcGIO51s",
      Title: "… l'hydraulique.",
      Quiz: 30,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recJ0NKTp0atoC7MT",
      Title: "... le solaire.",
      Quiz: 30,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recVVJT1uSny0gL9S",
      Title: "… l'incinération des déchets.",
      Quiz: 30,
      "Vrai/faux": "FAUX",
    },
  ],
  31: [
    {
      Id: "rec9XCWVz0tlC8WkX",
      Title:
        "Les matières premières des énergies fossiles brûlent très bien, elles sont également faciles à stocker et à transporter.",
      Quiz: 31,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recNQ9sOz8Gwddhsn",
      Title: "Ce sont des énergies renouvelables peu polluantes.",
      Quiz: 31,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recmoaGX8fE373lo3",
      Title: "La quantité de ces matières premières est illimitée.",
      Quiz: 31,
      "Vrai/faux": "FAUX",
    },
  ],
  32: [
    {
      Id: "recRSurQ9y4rXFhid",
      Title: " Puits de lumière",
      Quiz: 32,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recq14g2pp3R9D8uA",
      Title: "Puits de gaz",
      Quiz: 32,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recEKfWOS5CWw0Y7c",
      Title: "Puits de feu ",
      Quiz: 32,
      "Vrai/faux": "VRAI ",
    },
  ],
  33: [
    {
      Id: "recRDYmg7yGuESK1z",
      Title: "Il y a plus de 300 ans.",
      Quiz: 33,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recTU4947gJe2y5Cu",
      Title: "Il y a plus de 300'000 ans.",
      Quiz: 33,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recVYsnlLxWlb8bi5",
      Title: "Il y a plus de 300 millions d'années.",
      Quiz: 33,
      "Vrai/faux": "VRAI ",
    },
  ],
  34: [
    {
      Id: "recOD5M44SHN4Jw4P",
      Title: "Un engin pour extraire le pétrole.",
      Quiz: 34,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recV7QYG2RL31pTdE",
      Title: "Un appareil pour mesurer la qualité du pétrole.",
      Quiz: 34,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recp9UyMubCwY8U7l",
      Title: "Une série télévisée allemande diffusée entre 1974 et 1998.",
      Quiz: 34,
      "Vrai/faux": "FAUX",
    },
  ],
  35: [
    {
      Id: "reca95zLFxXv5EvEi",
      Title: "Depuis environ 1'000 ans",
      Quiz: 35,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recmewN5O6u1jUMhK",
      Title: "Depuis environ 500 ans",
      Quiz: 35,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rectci34NPpoNl5p7",
      Title: "Depuis environ 200 ans",
      Quiz: 35,
      "Vrai/faux": "VRAI ",
    },
  ],
  36: [
    { Id: "rec1zL38q1SBZKnGU", Title: "53%", Quiz: 36, "Vrai/faux": "FAUX" },
    { Id: "rechvQof1RySE9fhs", Title: "69%", Quiz: 36, "Vrai/faux": "FAUX" },
    { Id: "recpSo3RiF2APMNcF", Title: "78%", Quiz: 36, "Vrai/faux": "VRAI " },
  ],
  37: [
    {
      Id: "recKtNSXnVzwGPpxt",
      Title:
        "Car elles sont issues de la décomposition d'organismes vivants dans les sols sur plusieurs centaines de millions d'années.",
      Quiz: 37,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recosbzAHN0wOnzxe",
      Title: "Car on les exploite depuis des millions d'années.",
      Quiz: 37,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recpSeO7c8nRklaBz",
      Title: "Car elles sont démodées.",
      Quiz: 37,
      "Vrai/faux": "FAUX",
    },
  ],
  38: [
    {
      Id: "recbnJI9O8h6P7iJF",
      Title: "Le charbon ",
      Quiz: 38,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recfXi26lkuUIb2Uy",
      Title: "Le pétrole",
      Quiz: 38,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recRE8c3Cqz6TLAgQ",
      Title: "Le gaz ",
      Quiz: 38,
      "Vrai/faux": "FAUX",
    },
  ],
  39: [
    {
      Id: "rec0mzHKQTgaSBfzf",
      Title:
        "Car lorsque les combustibles fossiles sont brûlés, ils libèrent dans l'air de grandes quantités de dioxyde de carbone (CO<sub>2</sub>).",
      Quiz: 39,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recxVa7zEjfkAN71Q",
      Title: "Car elles exploitent des ressources limitées.",
      Quiz: 39,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recAkDmTMy6Kk3id7",
      Title: "Car leur fumée crée du brouillard.",
      Quiz: 39,
      "Vrai/faux": "FAUX",
    },
  ],
  40: [
    {
      Id: "recUSHzIPFi5p2hVo",
      Title:
        "Le stockage et le transport du pétrole sont difficiles, mais ses ressources sont illimitées.",
      Quiz: 40,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recZh9HTJ0UdpdFUg",
      Title:
        "Le stockage et le transport du pétrole sont faciles, mais ses ressources sont limitées.",
      Quiz: 40,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rec2vXtS0qbyoC3qO",
      Title:
        "Le stockage et le transport du pétrole sont faciles, et ses ressources sont illimitées.",
      Quiz: 40,
      "Vrai/faux": "FAUX",
    },
  ],
  41: [
    {
      Id: "recJNhPnkwTe5N0Lv",
      Title: "… ce qui provoque le réchauffement climatique.",
      Quiz: 41,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reciyCLqwUGG6W5tp",
      Title:
        "… une technique visant à récupérer les déchets et à les réintroduire après traitement dans le cycle de production.",
      Quiz: 41,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recpQIBcQquPiD2t4",
      Title: "… le nom des bras des éoliennes.",
      Quiz: 41,
      "Vrai/faux": "FAUX",
    },
  ],
  42: [
    {
      Id: "rec7jKxuwr97HQVDf",
      Title: "… transférer de l'eau.",
      Quiz: 42,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rectL7TDt0UZZe5vN",
      Title: " … transférer l'électricité.",
      Quiz: 42,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reczwkCAwr3GK1oSa",
      Title: "… transférer le pétrole.",
      Quiz: 42,
      "Vrai/faux": "VRAI ",
    },
  ],
  43: [
    {
      Id: "recVPjCvoNaoQ4u39",
      Title: "Le watt (W)",
      Quiz: 43,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recZJFfCzpQGdqSTr",
      Title: "Le joule (J)",
      Quiz: 43,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recvaSdF7BaLztMw4",
      Title: "Le becquerel (Bq)",
      Quiz: 43,
      "Vrai/faux": "FAUX",
    },
  ],
  44: [
    {
      Id: "recbzGOhKAbT1YCru",
      Title: "L'énergie varie avec la météo.",
      Quiz: 44,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recA3hMpygz46c2le",
      Title: "L'énergie se transforme.",
      Quiz: 44,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recEstMOGQPku2s2w",
      Title: "L'énergie disparaît avec le temps.",
      Quiz: 44,
      "Vrai/faux": "FAUX",
    },
  ],
  45: [
    {
      Id: "recdjrVcSO7mqBLHk",
      Title:
        "Développer le nucléaire, promouvoir la production d'énergies renouvelables, économiser l'énergie et augmenter l'efficacité énergétique.",
      Quiz: 45,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reciNLuABTjvDl14q",
      Title:
        "Sortir du nucléaire, promouvoir la production d'énergies renouvelables, économiser l'énergie et augmenter l'efficacité énergétique.",
      Quiz: 45,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recD3vF5gaK7X9V8l",
      Title:
        "Sortir du nucléaire, promouvoir la production d'énergies fossiles, économiser l'énergie et augmenter l'efficacité énergétique.",
      Quiz: 45,
      "Vrai/faux": "FAUX",
    },
  ],
  46: [
    {
      Id: "recM3bQ9Ncm507qpM",
      Title:
        "Réduire les émissions de CO<sub>2</sub> de la Suisse pour être neutre en 2050.",
      Quiz: 46,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recWW73yqqNBm2Ib4",
      Title: "Réduire l'utilisation du pétrole d'ici 2030.",
      Quiz: 46,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recB65AWmKMXyl6IF",
      Title: "Augmenter la plantation d'arbres en ville.",
      Quiz: 46,
      "Vrai/faux": "FAUX",
    },
  ],
  47: [
    {
      Id: "recbQjRj62AGbGxQz",
      Title: "L'énergie musculaire",
      Quiz: 47,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recPMlZ0jAuDId2qE",
      Title: "L'énergie nucléaire ",
      Quiz: 47,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rechZDUtehaeRxMdG",
      Title: " L'énergie solaire",
      Quiz: 47,
      "Vrai/faux": "FAUX",
    },
  ],
  48: [
    {
      Id: "recYVN9vWxMA1jltL",
      Title: "Ne pas faire sécher son linge sur les radiateurs.",
      Quiz: 48,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec17HLu7mNcBM6ru",
      Title:
        "Utiliser des énergies fossiles plutôt que la biomasse pour produire de l'énergie.",
      Quiz: 48,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recBZMpkLcN5YXVcD",
      Title:
        "Installer des massifs de plantes aromatiques pour les insectes pollinisateurs.",
      Quiz: 48,
      "Vrai/faux": "VRAI ",
    },
  ],
  49: [
    {
      Id: "recbiXaXpUJ1mobYH",
      Title:
        "… remplacer les énergies fossiles par des énergies renouvelables.",
      Quiz: 49,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recTod0xtzc0Sss3U",
      Title:
        "… remplacer les énergies renouvelables par des énergies fossiles.",
      Quiz: 49,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reclXvOZV5OjARjjd",
      Title:
        "… remplacer les installations solaires par des installations éoliennes.",
      Quiz: 49,
      "Vrai/faux": "FAUX",
    },
  ],
  50: [
    {
      Id: "recQwbha32BnGT805",
      Title: "C’est la diversité de toutes les formes de vie sur Terre.",
      Quiz: 50,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "reclFhkVZbxPySKZF",
      Title: "Un magazine de produits biologiques.",
      Quiz: 50,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recmvYiPm10p6yHeA",
      Title: "Une espèce animale rare.",
      Quiz: 50,
      "Vrai/faux": "FAUX",
    },
  ],
  51: [
    {
      Id: "recKWvAkWH307WrAA",
      Title:
        "Une pompe à chaleur qui utilise une géothermie dite «à basse température».",
      Quiz: 51,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rec5HHr0dhSSW9YPW",
      Title: "Un silo de biomasse humide doté d'un digesteur.",
      Quiz: 51,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recATUQcnf4tyoVIr",
      Title: "Une installation pétrothermale de géothermie profonde.",
      Quiz: 51,
      "Vrai/faux": "FAUX",
    },
  ],
  52: [
    {
      Id: "reccLp3QkqReAJK11",
      Title: "... un astre lumineux.",
      Quiz: 52,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recK7DnT1TcVg7tgA",
      Title: "... une énergie renouvelable.",
      Quiz: 52,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recOylW9emPwi9Zvv",
      Title: "... une science.",
      Quiz: 52,
      "Vrai/faux": "FAUX",
    },
  ],
  53: [
    {
      Id: "recb7zQJCFBOKU6hH",
      Title: "Plus de dix",
      Quiz: 53,
      "Vrai/faux": "FAUX",
    },
    { Id: "recdMZgN0XFkn1Wbf", Title: "Trois", Quiz: 53, "Vrai/faux": "VRAI " },
    {
      Id: "recROf74bvgxpZq8s",
      Title: "Il n'y a qu'une façon d'exploiter la géothermie.",
      Quiz: 53,
      "Vrai/faux": "FAUX",
    },
  ],
  54: [
    {
      Id: "recM4nG95XUai2tIi",
      Title:
        "On utilise l'énergie géothermique pour le chauffage et la production de gaz naturel.",
      Quiz: 54,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recTusabTMsdghbn0",
      Title:
        "On utilise l'énergie géothermique pour le chauffage et la production d’électricité.",
      Quiz: 54,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rec0IVgq3ZxwOOFPd",
      Title:
        "On exploite l'énergie géothermique pour produire des biocarburants.",
      Quiz: 54,
      "Vrai/faux": "FAUX",
    },
  ],
  55: [
    { Id: "recfGEuzo3iXbdnAX", Title: "500 m", Quiz: 55, "Vrai/faux": "FAUX" },
    { Id: "recRDnUIzuVs6Dlgm", Title: "2 km", Quiz: 55, "Vrai/faux": "FAUX" },
    { Id: "recUwrTza0S4gtgkb", Title: "5 km", Quiz: 55, "Vrai/faux": "VRAI " },
  ],
  56: [
    {
      Id: "recfYrJKuAxa5e9W6",
      Title: "La chaleur du soleil ",
      Quiz: 56,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recLnaVFCjlWf2PIG",
      Title: " La chaleur des maisons ",
      Quiz: 56,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recklEx6UysNLKD5D",
      Title: "La chaleur de la terre",
      Quiz: 56,
      "Vrai/faux": "VRAI ",
    },
  ],
  57: [
    { Id: "rec1UECGYn8WqGNyO", Title: "150°C", Quiz: 57, "Vrai/faux": "VRAI " },
    { Id: "rec48IS2cxVpRW0oW", Title: "200 °C", Quiz: 57, "Vrai/faux": "FAUX" },
    { Id: "recwF7UClLnoGTTJA", Title: "50°C", Quiz: 57, "Vrai/faux": "FAUX" },
  ],
  58: [
    {
      Id: "recIKdwyHJzi6gmXL",
      Title: "La pompe à chaleur",
      Quiz: 58,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recJJwKYwjX9y45bL",
      Title: "Les panneaux solaires thermiques",
      Quiz: 58,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recmsFMykvsZ2a11h",
      Title: "Le forage profond",
      Quiz: 58,
      "Vrai/faux": "FAUX",
    },
  ],
  59: [
    {
      Id: "reclbJixv3eyEFTzW",
      Title:
        "Les centrales géothermiques ne peuvent être construites que dans des lieux spécialement propices.",
      Quiz: 59,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recA0mWA0Ff4otRIE",
      Title:
        "Il est possible de construire des centrales géothermiques partout sur la planète, car le sous-sol de la Terre est toujours chaud.",
      Quiz: 59,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recCZneysb3cWif8h",
      Title: "Les centrales géothermiques sont très polluantes.",
      Quiz: 59,
      "Vrai/faux": "FAUX",
    },
  ],
  60: [
    {
      Id: "rec7kqNZqJkX7ufHY",
      Title: "La géothermie est disponible 24h/24 et indépendante de la météo.",
      Quiz: 60,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recetnd6f5g6CDHuG",
      Title: "La géothermie vient du grec et signifie «eau chaude».",
      Quiz: 60,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "reczI5OgEOSYOi8Jt",
      Title:
        "Le mot géothermie vient du grec, «géo» signifiant Terre, et «thermos», chaleur.",
      Quiz: 60,
      "Vrai/faux": "FAUX",
    },
  ],
  61: [
    {
      Id: "recdSPjruRrH8ZKQ0",
      Title:
        "Il ne produit ni déchets ni CO<sub>2</sub> et utilise des ressources puissantes et abondantes en Suisse (mais dont il faut prendre soin!).",
      Quiz: 61,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recn1vFfjxzyigafA",
      Title:
        "Quand l'eau passe dans les installations hydrauliques, elle est traitée chimiquement pour être encore plus propre à sa sortie.",
      Quiz: 61,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recyP0MxnrWpn5EFV",
      Title:
        "Il ne produit pas de CO<sub>2</sub>, mais ses ressources sont limitées.",
      Quiz: 61,
      "Vrai/faux": "FAUX",
    },
  ],
  62: [
    {
      Id: "reccWWz33bKJTOMKW",
      Title:
        "Les premières sont des installations dites «à basse chute», tandis que les secondes sont «à haute chute».",
      Quiz: 62,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recJKSdKuW8BOQMba",
      Title:
        "La manière dont l'eau est utilisée: les premières installations sont construites sur le cours d'eau, et les deuxièmes «empruntent» l'eau avec une conduite.",
      Quiz: 62,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recXEmskPfyMusZ7P",
      Title:
        "Il n'y a pas de différence. Les deux bloquent les cours d'eau, créant ainsi des lacs.",
      Quiz: 62,
      "Vrai/faux": "FAUX",
    },
  ],
  63: [
    {
      Id: "recO1uQ4JR8SB7Mf4",
      Title: "… un barrage.",
      Quiz: 63,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recUwAOA1qXEz3nAp",
      Title: "… une petite hydraulique.",
      Quiz: 63,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec1tqRSg64c7QPxu",
      Title: "… une centrale au fil de l'eau.",
      Quiz: 63,
      "Vrai/faux": "VRAI ",
    },
  ],
  64: [
    {
      Id: "rec8UunwMZOrfyAkj",
      Title: "À accumuler de l'eau pour produire de l'électricité.",
      Quiz: 64,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recB3JMAI8W5HxfB3",
      Title: "À créer des lacs pour les loisirs.",
      Quiz: 64,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recB9hbEKjJdPy7Rt",
      Title: "À éviter les crues.",
      Quiz: 64,
      "Vrai/faux": "FAUX",
    },
  ],
  65: [
    {
      Id: "recWv0NEABlRFw2HB",
      Title: "… dans une centrale hydraulique.",
      Quiz: 65,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recXPZel8oJ1mtNhN",
      Title: "… dans une éolienne.",
      Quiz: 65,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recnHZTmGsAUMRYbP",
      Title: "… dans une centrale nucléaire.",
      Quiz: 65,
      "Vrai/faux": "FAUX",
    },
  ],
  66: [
    {
      Id: "recbiQtj3oXVbjva7",
      Title: "Le barrage de Rossens (FR)",
      Quiz: 66,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recnIxrE6CSc6g0Wk",
      Title: "Le barrage de Châtelot (NE)",
      Quiz: 66,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recp43Ht937neEH7H",
      Title: "Le barrage de la Grande Dixence (VS)",
      Quiz: 66,
      "Vrai/faux": "VRAI ",
    },
  ],
  67: [
    {
      Id: "recl6AbK9o6SVgaca",
      Title:
        "Car elle s'approvisionne dans le plus grand lac d'Europe à proximité, et dispose ainsi de beaucoup d'eau.",
      Quiz: 67,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recqhuOUc3CMr3Ofj",
      Title: "Car elle utilise la technologie de pompage-turbinage.",
      Quiz: 67,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recz72YeVRLOULzYa",
      Title: "Car elle ressemble à une pile.",
      Quiz: 67,
      "Vrai/faux": "FAUX",
    },
  ],
  68: [
    { Id: "rec6Cemud9byRFsVu", Title: "60%", Quiz: 68, "Vrai/faux": "FAUX" },
    { Id: "recbVGCa0akzfp95D", Title: "45%", Quiz: 68, "Vrai/faux": "FAUX" },
    { Id: "recXCBdHWFLA5RRzB", Title: "15%", Quiz: 68, "Vrai/faux": "VRAI " },
  ],
  69: [
    {
      Id: "recKgeDBXQ3Qc2DLr",
      Title: "Au Tessin ",
      Quiz: 69,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recr2jGqTpmmQSt4T",
      Title: "En Chine",
      Quiz: 69,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recuPwAcajCDZA9x7",
      Title: "Au Brésil ",
      Quiz: 69,
      "Vrai/faux": "FAUX",
    },
  ],
  70: [
    { Id: "recGGjSa1x4qFBiHB", Title: "70%", Quiz: 70, "Vrai/faux": "FAUX" },
    { Id: "reckqQEUrzMRgPgbS", Title: "80%", Quiz: 70, "Vrai/faux": "FAUX" },
    { Id: "recC8Ri43DHg0kupq", Title: "90%", Quiz: 70, "Vrai/faux": "VRAI " },
  ],
  71: [
    {
      Id: "recZfUspzBb6oVXdB",
      Title:
        "Avec le réchauffement climatique, le niveau de la mer baisse et les installations ne sont pas assez profondes.",
      Quiz: 71,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recyfI3Z2LhoPG1WN",
      Title:
        "La mer et l'océan sont des puissances difficiles à maîtriser. Leur eau est salée et le sel est très corrosif.",
      Quiz: 71,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recDQXb15bvBmj6rn",
      Title: "L'énergie produite par les éoliennes offshore suffit.",
      Quiz: 71,
      "Vrai/faux": "FAUX",
    },
  ],
  72: [
    {
      Id: "recetbO64LJhsEeXX",
      Title: "... de la direction et de la force du vent.",
      Quiz: 72,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recRn5ez4ANuno5tF",
      Title:
        "... de la position de la Lune et du Soleil par rapport à la Terre.",
      Quiz: 72,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recmovuUi5a3GDYr0",
      Title:
        "... des changements de températures extérieures entre le jour et la nuit.",
      Quiz: 72,
      "Vrai/faux": "FAUX",
    },
  ],
  73: [
    {
      Id: "rec6LvUp98TR8Yvnh",
      Title: "Elles exploitent les différences de température.",
      Quiz: 73,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recWqrzDGhk2uNPJP",
      Title:
        "Elles utilisent la différence de teneur en sel entre l'eau de mer et l'eau douce.",
      Quiz: 73,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec3fCDTn1fHoz57o",
      Title:
        "Elles fonctionnent grâce aux mouvements de vagues et de la houle.",
      Quiz: 73,
      "Vrai/faux": "VRAI ",
    },
  ],
  74: [
    {
      Id: "recPDBh6jzueJNU0E",
      Title: "L'énergie naturelle du cosmos dégagée pendant la nuit.",
      Quiz: 74,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reczhfu7Yb22uzMvN",
      Title:
        "L'énergie dégagée lors de la rencontre entre deux eaux avec des concentrations en sel différentes.",
      Quiz: 74,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recA6uR53GBPEQfuQ",
      Title: "L'énergie produite lorsqu'on brûle des os.",
      Quiz: 74,
      "Vrai/faux": "FAUX",
    },
  ],
  75: [
    {
      Id: "recbzpnpo196z4r2c",
      Title:
        "Elles exploitent les courants marins pour créer une énergie mécanique qui est ensuite transformée en électricité. ",
      Quiz: 75,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recKw6OngRgomKUPn",
      Title:
        "Elles utilisent la chaleur de l'eau pour la convertir en électricité.",
      Quiz: 75,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recgmI9lFcJoMs3Mx",
      Title:
        "Elles exploitent les chutes d'eau pour créer une énergie mécanique qui est ensuite transformée en électricité. ",
      Quiz: 75,
      "Vrai/faux": "FAUX",
    },
  ],
  76: [
    {
      Id: "recLQdkd8TQMgSQ0d",
      Title: "Depuis l'Antiquité",
      Quiz: 76,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recQ12vMqLTLcyUsD",
      Title:
        "Ce sont des énergies en développement, nous ne savons pas encore les utiliser.",
      Quiz: 76,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reczoLY3QfKkpFdmT",
      Title: "Depuis environ 100 ans",
      Quiz: 76,
      "Vrai/faux": "FAUX",
    },
  ],
  77: [
    {
      Id: "recd5bi2LukawuDCM",
      Title: "Hydrolienne",
      Quiz: 77,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recZtLfRLAiRAQxAn",
      Title: "Centrale au fil de l'eau",
      Quiz: 77,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recuilQVbYPI3AbfX",
      Title: "Installation houlomotrice",
      Quiz: 77,
      "Vrai/faux": "FAUX",
    },
  ],
  78: [
    { Id: "rec9QAOWy28bWidzL", Title: "0", Quiz: 78, "Vrai/faux": "VRAI " },
    { Id: "rec4drjDP549umNLS", Title: "20", Quiz: 78, "Vrai/faux": "FAUX" },
    { Id: "recySJZkrZqlA6QcC", Title: "50", Quiz: 78, "Vrai/faux": "FAUX" },
  ],
  79: [
    {
      Id: "recYJyGDJNXtd7gL4",
      Title: "En Corée du Sud et en France ",
      Quiz: 79,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rec3wEAD2kZaoV1rO",
      Title: "En Irlande et en France",
      Quiz: 79,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recj76m9PPAYmqU7K",
      Title: "En Espagne et en Norvège ",
      Quiz: 79,
      "Vrai/faux": "FAUX",
    },
  ],
  80: [
    {
      Id: "rece7OUmBb3bErYaB",
      Title: "Usine méromotrice",
      Quiz: 80,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recGGKPwM9M5bFmPT",
      Title: "Usine salimotrice",
      Quiz: 80,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recJJDDNBZAusVCPy",
      Title: "Usine marémotrice",
      Quiz: 80,
      "Vrai/faux": "VRAI ",
    },
  ],
  81: [
    {
      Id: "recowtalgouy9KuIR",
      Title:
        "Maintenir la température de notre corps constante; utiliser nos muscles.",
      Quiz: 81,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rectVtK5gqZVlhll8",
      Title: "Utiliser nos muscles; digérer.",
      Quiz: 81,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reczvWDbZdM8HuxMq",
      Title: "Maintenir la température de notre corps constante; manger.",
      Quiz: 81,
      "Vrai/faux": "FAUX",
    },
  ],
  82: [
    {
      Id: "recGmUDWfOuMIM2Bt",
      Title: "Dans les énergies fossiles",
      Quiz: 82,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recT4aEtcLq66UuQH",
      Title: "Dans l'eau",
      Quiz: 82,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recCNAQ7Ikp0nkeBz",
      Title: "Dans la nourriture",
      Quiz: 82,
      "Vrai/faux": "VRAI ",
    },
  ],
  83: [
    {
      Id: "recPtzzw84XBz9Xj4",
      Title: "Non, impossible!",
      Quiz: 83,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec1GgbRdqf7FndP9",
      Title: "Oui, ça existe déjà!",
      Quiz: 83,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recoSbTOY3F7VGpoN",
      Title: "Oui, mais ça n'existe pas encore!",
      Quiz: 83,
      "Vrai/faux": "FAUX",
    },
  ],
  84: [
    {
      Id: "rec6hA9MtYpiJBMQv",
      Title: "… ne consomme pas d'énergie car il n'en produit pas.",
      Quiz: 84,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recfkaa7RkUsltKtq",
      Title: "… pourrait se passer de nourriture.",
      Quiz: 84,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reczd8Qe5EtPeiKQx",
      Title:
        "… utilise de l'énergie chimique pour faire fonctionner son organisme et son corps libère de l'énergie thermique (=chaleur).",
      Quiz: 84,
      "Vrai/faux": "VRAI ",
    },
  ],
  85: [
    {
      Id: "recPW9HCiyyJzf7ef",
      Title: "… la force du vent.",
      Quiz: 85,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recW7aEmhiP7W1TOl",
      Title: "… l'énergie thermique humaine.",
      Quiz: 85,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recpOxIYtFokaxyxL",
      Title: "… l'énergie musculaire humaine.",
      Quiz: 85,
      "Vrai/faux": "VRAI ",
    },
  ],
  86: [
    {
      Id: "recGTc3JMsK6NiTRS",
      Title: "… 22 à 32 watts.",
      Quiz: 86,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec4ioVPMDMcfa7Pt",
      Title: "… 2200 à 3200 watts.",
      Quiz: 86,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recsmS5UFrijm2n1Q",
      Title: "… 220 à 320 watts.",
      Quiz: 86,
      "Vrai/faux": "VRAI ",
    },
  ],
  87: [
    {
      Id: "recaeXcuv4AZiQbQ9",
      Title: "… de 10 à 40 watts durant une heure d'exercice vigoureux.",
      Quiz: 87,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recxecrZ7FxIObOT1",
      Title: "… de 50 à 150 watts durant une heure d'exercice vigoureux.",
      Quiz: 87,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recxPMLrcDGPuS6Ss",
      Title: "… de 200 à 350 watts durant une heure d'exercice vigoureux.",
      Quiz: 87,
      "Vrai/faux": "FAUX",
    },
  ],
  88: [
    {
      Id: "recMvuKq0ZNpHgPxB",
      Title:
        "… l’énergie chimique est transformée en énergies mécanique et thermique.",
      Quiz: 88,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recWG4N7KG8AgXzZZ",
      Title: "… l’énergie thermique est transformée en énergie chimique.",
      Quiz: 88,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recAYZZNhbKNZRyRE",
      Title: "… l’énergie thermique est transformée en énergie mécanique.",
      Quiz: 88,
      "Vrai/faux": "FAUX",
    },
  ],
  89: [
    {
      Id: "reccFlVfizpKaeFPw",
      Title:
        "Parce que, comme les chiens, les chevaux sont les meilleurs amis des êtres humains.",
      Quiz: 89,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec32ubpjPPZW7Azp",
      Title: "Pour une raison historique.",
      Quiz: 89,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recD4YzZJBzz8mNqr",
      Title: "Il n'y a pas d'explications.",
      Quiz: 89,
      "Vrai/faux": "FAUX",
    },
  ],
  90: [
    {
      Id: "recZxJmIiMXEI1gGN",
      Title: "Un animal qui est élevé pour tirer les charges.",
      Quiz: 90,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recvsvZfLCHNhsVvN",
      Title: "Un animal",
      Quiz: 90,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recC9rWJ4Pzq737ZM",
      Title: "Un animal qui est élevé pour la traite (action de traire).",
      Quiz: 90,
      "Vrai/faux": "FAUX",
    },
  ],
  91: [
    {
      Id: "recJS3WEZHXaCg2hw",
      Title: "Crocus",
      Quiz: 91,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recPiceT58wu2ff5U",
      Title: "Carrousel ",
      Quiz: 91,
      "Vrai/faux": "FAUX",
    },
    { Id: "recELW7a0pHEpkpFp", Title: "Lotus", Quiz: 91, "Vrai/faux": "FAUX" },
  ],
  92: [
    {
      Id: "recRtKKORKg2qkxCL",
      Title: "Il est radioactif.",
      Quiz: 92,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recZeOVimGlX39GHq",
      Title: " On le trouve surtout dans les Alpes.",
      Quiz: 92,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec2qPTO1zhLqAz3z",
      Title: "Il dégage du gaz à effet de serre.",
      Quiz: 92,
      "Vrai/faux": "FAUX",
    },
  ],
  93: [
    {
      Id: "recjUOIE6UxfbVM7c",
      Title: "Lucens",
      Quiz: 93,
      "Vrai/faux": "VRAI ",
    },
    { Id: "recnHSpHLaG6DOeum", Title: "Bex", Quiz: 93, "Vrai/faux": "FAUX" },
    {
      Id: "recBNze6wy1kKmV6f",
      Title: "Vionnaz ",
      Quiz: 93,
      "Vrai/faux": "FAUX",
    },
  ],
  94: [
    {
      Id: "recblHskJJiXC23UO",
      Title: "… découverte à la fin du 19<sup>e</sup> siècle.",
      Quiz: 94,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recJooGJ2jQk8r1YQ",
      Title: "… d'origine fossile.",
      Quiz: 94,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recW0lvwecfDuaLYS",
      Title:
        "… qui est utilisée pour produire de l'électricité à partir de sources renouvelables.",
      Quiz: 94,
      "Vrai/faux": "FAUX",
    },
  ],
  95: [
    {
      Id: "recKt4zyd2RRtoZmE",
      Title: "Pétrole",
      Quiz: 95,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recUV30e9yX6UP0xF",
      Title: "Charbon",
      Quiz: 95,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recCxZgzrENFIcdeB",
      Title: "Uranium",
      Quiz: 95,
      "Vrai/faux": "VRAI ",
    },
  ],
  96: [
    {
      Id: "recbS7S4d3lipWpRK",
      Title: "… 10% de la production d'électricité.",
      Quiz: 96,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recdLIK8r9WjPeNpw",
      Title: "… un tiers de la production d'électricité.",
      Quiz: 96,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recFLmFXgPaQkOJZz",
      Title: "… la moitié de la production d'électricité.",
      Quiz: 96,
      "Vrai/faux": "FAUX",
    },
  ],
  97: [
    { Id: "recu7YH2OVovYkz2y", Title: "80 ans", Quiz: 97, "Vrai/faux": "FAUX" },
    {
      Id: "recx5mb9BcVFC7gjN",
      Title: "70 ans",
      Quiz: 97,
      "Vrai/faux": "VRAI ",
    },
    { Id: "recB0KuuP404aSf5x", Title: "90 ans", Quiz: 97, "Vrai/faux": "FAUX" },
  ],
  98: [
    {
      Id: "rec68OgvSAMUW1cd9",
      Title:
        "… le processus utilisé dans les réacteurs de centrales nucléaires.",
      Quiz: 98,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recYr9zsl1lmM5tql",
      Title:
        "… le processus pour extraire l'uranium au sein des centrales nucléaires.",
      Quiz: 98,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rectsdNseW7dk3mxL",
      Title: "… le processus de gestion des déchets radioactifs.",
      Quiz: 98,
      "Vrai/faux": "FAUX",
    },
  ],
  99: [
    {
      Id: "recYMSFgoOrWdE4nu",
      Title: "Enceinte de confinement",
      Quiz: 99,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec459x9w0OCZr515",
      Title: "Digesteur",
      Quiz: 99,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recuXYdwx5q8UKApJ",
      Title: "Turbine",
      Quiz: 99,
      "Vrai/faux": "FAUX",
    },
  ],
  100: [
    {
      Id: "recaZECO5goS3n8rP",
      Title:
        "La radioactivité mesure l'activité de toutes les radios dans le monde.",
      Quiz: 100,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recim5CYAIEmOPCVu",
      Title:
        "La radioactivité est le phénomène par lequel le noyau de certains atomes se désintègre en libérant une grande quantité d'énergie.",
      Quiz: 100,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recDEiWT9C4HtrmvZ",
      Title: "C'est la mesure du taux d'écoute d'une radio.",
      Quiz: 100,
      "Vrai/faux": "FAUX",
    },
  ],
  101: [
    {
      Id: "rec9BNS3NLQjkwpUm",
      Title: "Autriche",
      Quiz: 101,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reckFcor52M6xr7Xd",
      Title: "Suisse ",
      Quiz: 101,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recxdoIpZelEAKIEq",
      Title: "Italie",
      Quiz: 101,
      "Vrai/faux": "FAUX",
    },
  ],
  102: [
    {
      Id: "rec3WcUCA0xbJzcKK",
      Title: "Centrales d'accumulation solaire",
      Quiz: 102,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec5jxg4tT18sfKyv",
      Title: "Centrales solaires thermodynamiques",
      Quiz: 102,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recpfrXhrTvoFeXrO",
      Title: "Centrales solaires photovoltaïques",
      Quiz: 102,
      "Vrai/faux": "FAUX",
    },
  ],
  103: [
    {
      Id: "recfHIex1tqDxmVYs",
      Title: "De silicone",
      Quiz: 103,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec1G5brMgsKTaLTK",
      Title: "De silicium",
      Quiz: 103,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recovbwt1Pp0qXwYX",
      Title: "De calcium",
      Quiz: 103,
      "Vrai/faux": "FAUX",
    },
  ],
  104: [
    {
      Id: "recICaC0jG3x7sfWM",
      Title: "Entre 35 et 55 ans",
      Quiz: 104,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec0s5pWmKgmPH5FO",
      Title: "Entre 5 et 10 ans",
      Quiz: 104,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recERrA1LaPUXB1gC",
      Title: "Entre 20 et 30 ans",
      Quiz: 104,
      "Vrai/faux": "VRAI ",
    },
  ],
  105: [
    {
      Id: "recVd76Gkd0HmHgvp",
      Title: "… de biogaz.",
      Quiz: 105,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "reclYy2kAoRUbyQZs",
      Title: "… d'eau chaude et de chauffage d'appoint.",
      Quiz: 105,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recrKtHPGzh3kRuiA",
      Title: "... d'électricité.",
      Quiz: 105,
      "Vrai/faux": "FAUX",
    },
  ],
  106: [
    {
      Id: "recKCnIKTKnIGqKZ5",
      Title: " … de biocarburant.",
      Quiz: 106,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "rec5aLNZGcCPEMNjq",
      Title: "... d'électricité.",
      Quiz: 106,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "rectLrhMXn7QlxeDB",
      Title: "… d'eau chaude et de chauffage d'appoint.",
      Quiz: 106,
      "Vrai/faux": "FAUX",
    },
  ],
  107: [
    {
      Id: "recWfR7qoVyCxieWE",
      Title: "… le type de panneau permettant de produire de la chaleur.",
      Quiz: 107,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recZFlJpkfaskeBmD",
      Title: "… le type de panneau permettant de produire de l'électricité.",
      Quiz: 107,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recu6ifSQ8j61r50T",
      Title: "... le type de panneau permettant de produire de l'eau chaude.",
      Quiz: 107,
      "Vrai/faux": "FAUX",
    },
  ],
  108: [
    {
      Id: "recQzXpRpktQHVYeE",
      Title: "De 1 à 10 m<sup>2</sup>",
      Quiz: 108,
      "Vrai/faux": "FAUX",
    },
    {
      Id: "recYEhHxrkxkKQifH",
      Title: "De 1 à 5 m<sup>2</sup>",
      Quiz: 108,
      "Vrai/faux": "VRAI ",
    },
    {
      Id: "recZNOgPC88HYZumg",
      Title: "De 1 à 3 m<sup>2</sup>",
      Quiz: 108,
      "Vrai/faux": "FAUX",
    },
  ],
  109: [
    { Id: "rec8nTq2yR5v5bI7q", Title: "30% ", Quiz: 109, "Vrai/faux": "FAUX" },
    { Id: "recK4uBU7Hmv411AS", Title: "80%", Quiz: 109, "Vrai/faux": "VRAI " },
    { Id: "recq6QResq7b7udh0", Title: "60%", Quiz: 109, "Vrai/faux": "FAUX" },
  ],
  110: [
    { Id: "recc1HPNkexmmT5jM", Title: "5", Quiz: 110, "Vrai/faux": "FAUX" },
    { Id: "rec4V2VO9F2KbJ9qC", Title: "3", Quiz: 110, "Vrai/faux": "FAUX" },
    { Id: "recyT076OrZ1JSa0A", Title: "2", Quiz: 110, "Vrai/faux": "VRAI " },
  ],
};
