<template>
  <div class="content-wrapper">
    <div class="question-number">Question {{ questionNb }} / {{ quizMax }}</div>
    <hi-team-flag :team="team" />
    <div class="content">
      <h1 class="my-anim">{{ randomAnswer }}</h1>
      <div
        class="notification my-anim"
        v-html="explanation"
        :class="{ 'is-danger': isDanger, 'is-primary': isPrimary }"
      ></div>
      <button
        @click="submit"
        tag="button"
        class="button button-text button-theme"
      >
        {{ nextLabel }}
      </button>
    </div>
  </div>
</template>

<script>
import AnswerMixin from "./../mixins/AnswerMixin";
import SessionMixin from "./../mixins/SessionCookieMixin";
import { Teams, Themes, QuizMax } from "./../data";
import Questions from "./../questions";
import Anime from "animejs/lib/anime.es.js";
import bus from "./../bus";

export default {
  mixins: [AnswerMixin, SessionMixin],
  data() {
    return {
      quiz: null,
      found: false,
      explanation: "",
      question: "",
      session_id: null,
      truefalse: false,
      falseAnswers: ["Dommage :(", "Non...", "Faux", "Nope :/"],
      correctAnswers: ["Bravo!", "Yes :D", "Vrai!", "Joli :P", "Ouiiii"],
      isPrimary: false,
      isDanger: false,
      resultat: "",
      team: "",
      teamClass: "",
      theme: "",
      themeClass: "",
      nextLabel: "Question suivante",
      quizMax: QuizMax,
    };
  },
  computed: {
    randomAnswer: function () {
      if (this.isDanger) {
        return this.falseAnswers[
          Math.floor(Math.random() * this.falseAnswers.length)
        ];
      } else {
        return this.correctAnswers[
          Math.floor(Math.random() * this.correctAnswers.length)
        ];
      }
    },
    questionNb: function () {
      return this.quiz + 1;
    },
  },
  methods: {
    submit() {
      const vm = this;
      if (parseInt(this.quiz)+1 >= parseInt(QuizMax)) {
        vm.$router.push({ name: "Final" });
        return;
      } else {
        vm.next(this.quiz);
        vm.$router.push({ name: "Question" });
      }
    },
    runAnimation() {
      var btns = Array.from(this.$el.querySelectorAll(".my-anim"));
      console.log(btns);
      Anime(
        {
          targets: btns,
          translateY: [-60, 0],
          opacity: [0, 1],
          duration: 1000,
          delay: Anime.stagger(200),
        },
        "-=1000"
      );
    },
  },

  mounted() {
    this.runAnimation();
    window.history.forward(1);
    const vm = this;
    const truefalse = this.$route.params.truefalse;

    (this.truefalse = truefalse),
      (this.isPrimary = truefalse === "true"),
      (this.isDanger = truefalse === "false"),
      (this.resultat = truefalse === "true" ? "Juste" : "Faux");

    // Fetch session from cookies
    const record = this.fetchSession();
    const team = record["Team"];
    const teamClass = "team" + String(Teams.indexOf(team) + 1);
    const quiz = record["Quiz"];
    vm.found = true;
    vm.quiz = quiz;
    vm.nextLabel = quiz+1 >= QuizMax ? "Terminer le Quiz" : "Question Suivante";
    vm.team = team;
    vm.teamClass = teamClass;
    const questions = JSON.parse(record["Questions"]);
    const quizID = questions[quiz];

    const question = Object.values(Questions).filter(
      (q) => q["Quiz"] == quizID
    )[0];
    const theme = question["Theme"];
    const themeClass = Themes[theme];
    vm.question = question["Question"];
    vm.explanation = question["Explanation"];
    vm.theme = theme;
    vm.themeClass = themeClass;
    bus.$emit("themeChanged", {
      theme: theme,
      themeClass: themeClass,
    });

    // preload next images
    if (quiz >= 20) {
      return;
    }
    const preload = Questions[quiz + 1];
    const images = preload["Images"];
    const pixelRatio = window.devicePixelRatio;
    const width = screen.width;
    let size = "";
    if (width <= 780) {
      if (pixelRatio == 1) {
        size = "420x";
      } else {
        size = "840x";
      }
    } else if (width > 780 && width <= 1600) {
      if (pixelRatio == 1) {
        size = "700x";
      } else {
        size = "1400x";
      }
    } else {
      if (pixelRatio == 1) {
        size = "1180x";
      } else {
        size = "2360x";
      }
    }

    images.forEach(function (image) {
      let imageP = new Image();
      console.log(image, size);
      imageP.src = vm.imageproxy("/" + image, size);
    });
  },
};
</script>
