<template>
  <div class="banner banner-down">
    <div class="icons-wrapper">
      <img src="/assets/static/icons/icon-wind.svg" />
      <img src="/assets/static/icons/icon-sun.svg" />
      <img src="/assets/static/icons/icon-muscle.svg" />
      <img src="/assets/static/icons/icon-sea.svg" />
      <img src="/assets/static/icons/icon-geothermal.svg" />
      <img src="/assets/static/icons/icon-fossil.svg" />
      <img src="/assets/static/icons/icon-biomass.svg" />
      <img src="/assets/static/icons/icon-hydraulic.svg" />
      <img src="/assets/static/icons/icon-nuclear.svg" />
    </div>
  </div>
</template>

<script>
module.exports = {
  props: ["team"],
};
</script>
