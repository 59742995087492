<template>
  <div v-if="team === 'Grand Jeu des énergies'" class="team-flag">
    <span>{{ team }}</span>
  </div>
  <div v-else class="team-flag">
    <span>Équipe des {{ team }}</span>
    <icon-eclaireurs v-if="team === 'Éclaireurs'" class="team-icon" />
    <icon-sherpas v-if="team === 'Sherpas'" class="team-icon" />
    <icon-farfouilleurs v-if="team === 'Farfouilleurs'" class="team-icon" />
    <icon-guetteurs v-if="team === 'Guetteurs'" class="team-icon" />
    <icon-messagers v-if="team === 'Messagers'" class="team-icon" />
    <icon-pionniers v-if="team === 'Pionniers'" class="team-icon" />
    <icon-pisteurs v-if="team === 'Pisteurs'" class="team-icon" />
    <icon-ravitailleurs v-if="team === 'Ravitailleurs'" class="team-icon" />
    <icon-strateges v-if="team === 'Stratèges'" class="team-icon" />
    <icon-trappeurs v-if="team === 'Trappeurs'" class="team-icon" />
  </div>
</template>

<script>
module.exports = {
  props: ["team"],
};
</script>
