<template>
  <div class="img-wrapper">
    <picture>
      <source
        :srcset="
          (imageproxy('/' + image, '420x') + ' 1x',
          imageproxy('/' + image, '840x') + ' 2x')
        "
        media="(max-width: 780px)"
      />
      <source
        :srcset="
          (imageproxy('/' + image, '700x') + ' 1x',
          imageproxy('/' + image, '1400x') + ' 2x')
        "
        media="(min-width: 781px) and (max-width: 1600px)"
      />
      <source
        :srcset="
          (imageproxy('/' + image, '1180x') + ' 1x',
          imageproxy('/' + image, '2360x') + ' 2x')
        "
        media="(min-width: 1601px)"
      />
      <img class="ofi-image" alt="" :src="imageproxy('/' + image, '1180x')" />
    </picture>
    <div class="overlay"></div>
  </div>
</template>

<script>
import bus from "./../bus";
export default {
  props: ["image"],
  methods: {
    click() {
      bus.$emit("intermediarIsOver");
    },
  },
  mounted() {

  }
};
</script>
