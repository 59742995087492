<template>
  <header :class="['bg-' + themeClass, 'header']">
    <div class="logo-wrapper">
      <div id="logo">
        <img src="/assets/static/logo_explo.png" />
      </div>
      <div class="logo-title">
        <span>Quiz</span>
        <span>Explorateurs de l’énergie</span>
      </div>
      <div class="logo-romande">
        <object data="/assets/static/EXPLOS-LOGO-PAR-ROMANDE-ENERGIE-NoSpace.svg" type="image/svg+xml"></object>
      </div>
    </div>
    <div class="theme-wrapper">
 
      <transition name="beat">
        <icon-biomass v-if="themeClass === 'biomass'" class="theme-icon" />
        <icon-ecogesture
          v-if="themeClass === 'ecogesture'"
          class="theme-icon"
        />
        <icon-geothermal
          v-if="themeClass === 'geothermal'"
          class="theme-icon"
        />
        <icon-fossil v-if="themeClass === 'fossil'" class="theme-icon" />
        <icon-general v-if="themeClass === 'general'" class="theme-icon" />
        <icon-hydraulic v-if="themeClass === 'hydraulic'" class="theme-icon" />
        <icon-muscle v-if="themeClass === 'muscle'" class="theme-icon" />
        <icon-nuclear v-if="themeClass === 'nuclear'" class="theme-icon" />
        <icon-sea v-if="themeClass === 'marine'" class="theme-icon" />
        <icon-sun v-if="themeClass === 'solar'" class="theme-icon" />
        <icon-wind v-if="themeClass === 'wind'" class="theme-icon" />
      </transition>
      <div :class="themeClass" class="subtitle">{{ theme }}</div>
    </div>
  </header>
</template>

<script>
export default {
  props: ["themeClass", "theme", "team", "teamClass"],
};
</script>

<style lang="scss" scoped>
.beat-enter-active {
  animation: beat 1 1s;
}
.beat-leave-active {
  animation: beat 1 1s reverse;
}
@keyframes beat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
