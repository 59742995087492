<template>
  <div class="flex-container">
    <hi-header :themeClass="themeClass" :theme="theme" :team="team"></hi-header>
    <div :class="[teamClass, 'bg-' + themeClass, 'main-container']">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import bus from "./../bus";

export default {
  data() {
    return {
      // Default values
      team: "",
      teamClass: "team0",
      theme: "",
      themeClass: "start",
    };
  },
  created() {
    bus.$on("teamChanged", ({ team, teamClass }) => {
      this.team = team;
      this.teamClass = teamClass;
    });

    bus.$on("themeChanged", ({ theme, themeClass }) => {
      this.theme = theme;
      this.themeClass = themeClass;
    });
  },
};
</script>
