export default {
  1: {
    Id: "recJHmICONir4OPY0",
    Quiz: 1,
    Question: "As-tu reconnu l'installation présentée dans la vidéo? \n",
    Theme: "Biomasse",
    Explanation:
      "C'est une centrale de biomasse sèche! En utilisant les sous-produits de bois de la scierie voisine, la centrale Enerbois produit des pellets, de la chaleur et même de l'électricité. Comment? En brûlant les sous-produits du bois, de l'eau est chauffée, ce qui crée de la vapeur. La vapeur fait ensuite tourner une turbine, qui fait fonctionner un alternateur produisant ainsi de l'électricité! ",
    Intermediar: "video",
    ConsigneIntermediar:
      "Regarde attentivement cette vidéo pour pouvoir répondre à la question!",
    ConsigneURL: "https://player.vimeo.com/video/420262901",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  2: {
    Id: "recI8TJElI8KppGiZ",
    Quiz: 2,
    Question:
      "Comment s'appellent les grands silos dans lesquels est décomposée la biomasse humide pour produire du biogaz?",
    Theme: "Biomasse",
    Explanation:
      "C'est dans un digesteur que la matière organique est décomposée par des bactéries. Le biogaz résultant de cette fermentation peut être utilisé pour chauffer les foyers ou produire de l'électricité.",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  3: {
    Id: "recawhno6Jnsetlr6",
    Quiz: 3,
    Question:
      "Pourquoi l'énergie produite à partir de la biomasse joue-t-elle un rôle important pour atteindre les objectifs climatiques de la Suisse?",
    Theme: "Biomasse",
    Explanation:
      "L’énergie produite à partir de biomasse est renouvelable et respectueuse de l’environnement. L’utilisation de bioénergie émet autant de CO<sub>2</sub> dans l’atmosphère que la quantité de CO<sub>2</sub> absorbée antérieurement par les plantes.",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  4: {
    Id: "recTFGkB2l4XKWqle",
    Quiz: 4,
    Question: "Complète cette affirmation: l'énergie biomasse…",
    Theme: "Biomasse",
    Explanation:
      "Parmi les formes d’énergie utilisées par l'homme depuis la découverte du feu, l'énergie biomasse est la plus ancienne. Elle consiste à recycler de nombreuses matières organiques et en faire une source d’énergie.",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  5: {
    Id: "recfr2Yz9bMsLLurb",
    Quiz: 5,
    Question:
      "Le savais-tu? La biomasse désigne tout ce qui se compose de matières issues…",
    Theme: "Biomasse",
    Explanation:
      "Toutes ces matières organiques renouvelables peuvent être transformées en biogaz et permettent de produire de l’électricité, de la chaleur et du carburant pour la mobilité.",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  6: {
    Id: "recL9wuEN1K95VavW",
    Quiz: 6,
    Question:
      "Le savais-tu? Il existe plusieurs possibilités pour transformer un type de biomasse donné. On utilise la biomasse sèche tel que le bois…",
    Theme: "Biomasse",
    Explanation:
      "La plus grande centrale de biomasse sèche de Suisse romande est celle d'Enerbois. Elle permet de produire de l'énergie électrique pour environ 10'500 familles!",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  7: {
    Id: "rec7nBqc9xcSsVcMz",
    Quiz: 7,
    Question:
      "Depuis combien de temps utilisons-nous la biomasse comme énergie?",
    Theme: "Biomasse",
    Explanation:
      "Cela fait bien longtemps que l’on utilise la biomasse pour produire de l’énergie: il y a plus de 300’000 ans, les humains apprenaient à maîtriser le feu en brûlant du bois. Depuis, ils s’en servent pour se chauffer, s’éclairer ou encore cuire de la nourriture. Aujourd’hui encore, la biomasse constitue la source principale – voire l'unique source – d’énergie pour près de la moitié de l’humanité!",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  8: {
    Id: "rec8iCAPhedDmp4nQ",
    Quiz: 8,
    Question: "Qu'est-ce que l'essence «E10»?",
    Theme: "Biomasse",
    Explanation:
      "Dans certaines stations d'essence, on trouve de l'essence E10, contenant 10% d'éthanol produit à base de plantes riches en sucre ou en amidon, comme la betterave, la canne à sucre, le maïs, la pomme de terre ou le blé.",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  9: {
    Id: "reccZntKTdu1TldR3",
    Quiz: 9,
    Question: "De quelles matières est faite la biomasse?",
    Theme: "Biomasse",
    Explanation:
      "On appelle «biomasse» les matières issues des végétaux et des animaux, c'est-à-dire des matières organiques!",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  10: {
    Id: "recc3IzVWynoy4J8m",
    Quiz: 10,
    Question:
      "Le savais-tu? La biomasse est la source d’énergie principale pour plus de la moitié de la population mondiale. Mais de combien de pourcent couvre-t-elle les besoins énergétiques mondiaux?",
    Theme: "Biomasse",
    Explanation:
      "La biomasse couvre ne couvre que 10% des besoins énergétiques mondiaux. Nous sommes malheureusement encore fortement dépendants des énergies fossiles, qui elles représentent 80% de notre consommation!",
    Images: [
      "assets/images/biomass-1-1.jpg",
      "assets/images/Q32.jpg",
      "assets/images/COMPOST-EGHM24-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/SyKwpoVRzl2eQgc8zzUdqw/kIeGD7lCHC2UIdcm0glJWTSxgG2KC1Fc0pehqIkcN98zukTd5LaVkr2KKrvncwtVU-i5_u2t9t1KBv5AVJ7asH6wsbiSU1yNVtz33G-QYDYbscqwkzKwWh8JMEBKHnOAlY4PzRKFTxFFN-m3gD8geQ/34pMH7Kw9Xw-CnyYqis-EwNkNVq907dpeqCMl1Qyp70",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Aw3GvNnTFfc-TATlPqZbcQ/xZBRHPA2i7VA0KgjxQraj8dE2utNPMkeVrCGOFf-JM3MaEgUj1O6gvj5d03KBKyXoc2iN-gNqAw2s0Q2awMWfUGbndmTkyRm7QZgiuawo4wu7KTmYBBR7aMKyx762r90GtSxWkK3wDLCG61G0K5qIQ/W0OSJwItzVXc1j8JPWYYsPFWp7-HZ3yKAQrFqdr5Rjw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/YyQyg-Ksw12XVM3dN098Tg/CTSMi_NWbmZLhuBkXvb65zf47RSg4XAjI-_4dXtDAFUh9s__J_jOo9RBWRJV3cbnGkH_OezIkNQYPTG9dFicNaIEIX3teQnTGvGbqkQteRBAcbheg21HiKrRPPJk99_X9ijThmhQScf3lDczY9oXDg/PyFDS89Yb3maYLGJFKN_G2dCWNyYOV_L2pXgOwFZW1s",
    ],
  },
  11: {
    Id: "recdfvXVutUfa0YlK",
    Quiz: 11,
    Question:
      "Pour réduire notre empreinte écologique, en plus de consommer des produits locaux, il est conseillé de… ",
    Theme: "Éco-gestes",
    Explanation:
      "L'empreinte écologique est aussi liée au contenu de nos assiettes. L'élevage est une activité qui a un fort impact sur l'environnement: très gourmande en ressources, elle émet beaucoup d'émissions de gaz à effet de serre.",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  12: {
    Id: "rec5WASAMsOV1WIEX",
    Quiz: 12,
    Question:
      "Pour ne pas utiliser de l'énergie inutilement quand on part en vacances, avant de partir, il faut penser à... ",
    Theme: "Éco-gestes",
    Explanation:
      "Comme la maison reste vide durant un certain temps, il est recommandé de baisser le chauffage et s'assurer que tous les appareils électriques sont complètement éteints pour ne pas gaspiller de l'énergie.",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  13: {
    Id: "rec9BS3BJYy4ajjLW",
    Quiz: 13,
    Question: "Les éco-gestes, c'est quoi?",
    Theme: "Éco-gestes",
    Explanation:
      "Les éco-gestes peuvent réduire notre empreinte carbone individuelle jusqu'à 25%.",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  14: {
    Id: "recJgxUXZzVnLpTsa",
    Quiz: 14,
    Question: "Laquelle des ces trois listes ne contient que des éco-gestes?",
    Theme: "Éco-gestes",
    Explanation:
      "Les éco-gestes permettent de réduire notre consommation d'énergie au quotidien et contribuent à préserver notre planète!",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  15: {
    Id: "rec0jyWBh5q2zsWbW",
    Quiz: 15,
    Question:
      "En hiver, à quelle température est-il recommandé de régler le chauffage des pièces de sa maison ou son appartement?",
    Theme: "Éco-gestes",
    Explanation:
      "Il est conseillé de maintenir les pièces principales entre 19-20°C, et même 17-18°C dans les chambres à coucher. On considère que chaque degré supplémentaire correspond à 7% de consommation d'énergie en plus!",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  16: {
    Id: "recYx4pxyqrNVXz1V",
    Quiz: 16,
    Question: "Quelle est l'influence de nos éco-gestes sur le climat?",
    Theme: "Éco-gestes",
    Explanation:
      "En Suisse, les éco-gestes individuels permettraient de réduire les émissions totales de gaz à effet de serre de 16%.",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  17: {
    Id: "recTUZzAx9K5XhC42",
    Quiz: 17,
    Question:
      "Baisser la température de son logement de 1°C permet de réaliser une économie d’énergie de combien de pourcent?",
    Theme: "Éco-gestes",
    Explanation:
      "Chaque degré en moins permet d'économiser environ 7% d'énergie! Un petit conseil: diminue la température de ton logement de 0.5°C à la fois pour habituer ton corps! Ainsi, en quelques jours seulement, tu pourras atteindre la température recommandée (17°C dans la chambre et 19° dans le salon) sans altérer ton confort.",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  18: {
    Id: "rec276iVIIolFLvv1",
    Quiz: 18,
    Question:
      "En hiver, si nous voulons garder la chaleur d'une pièce pendant la nuit, que pouvons-nous faire? ",
    Theme: "Éco-gestes",
    Explanation:
      "En fermant stores et volets avant de nous coucher, nous pouvons réduire les déperditions de chaleur et maintenir tout le confort de notre intérieur.",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  19: {
    Id: "recGJRsdfy6v2ifCq",
    Quiz: 19,
    Question:
      "Le savais-tu? On peut récupérer l'eau de cuisson non salée pour arroser nos plantes! Mais à quoi peut servir l'eau de cuisson des pommes de terre?",
    Theme: "Éco-gestes",
    Explanation:
      "L’eau de cuisson non salée peut être utilisée pour arroser nos plantes: celle des légumes est un parfait engrais pour les fleurs et le potager, celle des œufs apporte du calcium et celle des pommes de terre est un excellent désherbant!",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  20: {
    Id: "rec1LboWzJ5xqonTr",
    Quiz: 20,
    Question:
      "Laquelle de ces trois propositions décrit les bons éco-gestes pour économiser l'eau?",
    Theme: "Éco-gestes",
    Explanation:
      "Préférer la douche au bain et éteindre le robinet quand on se brosse les dents sont des éco-gestes très connus. Mais savais-tu que le lave-vaisselle plein en mode «éco» utilise moins d'eau qu'un nettoyage à la main?",
    Images: [
      "assets/images/nick-page-aY3prngBwQI-unsplash-web.jpg",
      "assets/images/eco-1-2.jpg",
      "assets/images/web-noah-buscher-x8ZStukS2PM-unsplash.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bJAoCXTsEzkZ3tugtNPlwQ/F8FM0oI0qFOlYnPXRNnuss7vEMfdH0ouuDMi3FJshyXjZq7bscP6ocCuJB8N6FtKfUytW7rvZE8C7XZSh0IVXa3hBdWfWKhYWB8SpWATkp8JTOnojVIZYDqohr4ZtdIzFPUUtAv9s8GeZHkWZkv4lQ/4ydcdltplUxGN3BYZBUMpv2e0WslASaaou3gwGNjo_U",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/KhXJ-sn7sXIxj5bpG0QYEg/WS7YxNlg-IOzCx5mLaCE5txO0vsFk_8P2LYstiFr1mjgH9LJh0-pW0iYQYA8vPpcPmqO3bYE27vhcJiaSxwqBZoZQ-p433SwibkMwtipJJpZXTNZ14hlhR0U4VS68-g2rfbQw-tStUJpPDKIyc-tNA/DvZZAXaHlEMKcDe3v6YWrz8yJg_3UiPlpw0kOrAfY30",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/5xO78-3XnSct2zCKu4Mj5w/VLx-NNNCvGY2XeD46lBIf9s_ke4UCvtu5s8fr5CdhRDCjsTqN5GX-yqeS53yBu5ClLkkdITZkm0ar98-GrXt5hwi8f6gcrji84kf_yolunumAIH1tyDRryOPoWftFUvsxrzY9iwaKe50-4Dxd-IQMA/hUHq2fi1JuP4UxxcWydJ-MNeYCtW-ygXqTM1XsWq7BI",
    ],
  },
  21: {
    Id: "recsCmiQYpCofwLb9",
    Quiz: 21,
    Question:
      "Le savais-tu? Au 5<sup>e</sup> siècle avant Jésus-Christ déjà, les civilisations grecques et égyptiennes se servaient des premières éoliennes pour:",
    Theme: "Éolienne",
    Explanation:
      "Les Grecs et les Égyptiens utilisaient les premiers moulins à vent pour moudre leur grain et pomper l'eau. C'est seulement en 1888 qu'un scientifique américain réalise la première turbine éolienne capable de produire de l’électricité.",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  22: {
    Id: "rec9xChZ5vLg5A8hA",
    Quiz: 22,
    Question: "Quel est le meilleur emplacement pour une éolienne?",
    Theme: "Éolienne",
    Explanation:
      "Les éoliennes sont généralement placées dans des zones dégagées et venteuses. En Suisse, les sites appropriés se trouvent sur les crêtes du Jura, mais également dans les Alpes et les Préalpes, ainsi qu’à l’ouest du Plateau.",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  23: {
    Id: "recD1ncH6ehhLuuQK",
    Quiz: 23,
    Question:
      "Une éolienne de taille moyenne permet de couvrir les besoins en électricité d'environ…",
    Theme: "Éolienne",
    Explanation:
      "Une éolienne produit de l’électricité pour environ 1'400 foyers, soit pour un village d’environ 4'500 habitants. Et ce, durant une vingtaine d’années.",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  24: {
    Id: "recQwXYk81stgu3Vb",
    Quiz: 24,
    Question:
      "Combien de voitures électriques, parcourant 15'000 km par an, une éolienne peut-elle alimenter?",
    Theme: "Éolienne",
    Explanation:
      "Une éolienne produit suffisamment d’électricité pour alimenter 2'500 voitures électriques parcourant 15'000 km par an chacune, et ce pendant 20 ans. Ou pour faire 18'740 fois le tour du monde avec une voiture électrique!",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  25: {
    Id: "recWH60UNyn5oZ5uX",
    Quiz: 25,
    Question:
      "Le savais-tu? Au terme de sa durée d’exploitation (20 à 25 ans), une éolienne se démonte en environ…",
    Theme: "Éolienne",
    Explanation:
      "Une grande éolienne en Suisse produit de l’électricité pour environ 4'500 personnes pendant 20 à 25 ans. Au terme de sa durée d’exploitation, elle peut être démantelée en un mois seulement. De plus, 80 à 90% des matériaux sont recyclables et peuvent être réutilisés.",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  26: {
    Id: "rec9mWM1XiwV1NP6g",
    Quiz: 26,
    Question: "Pourquoi l'énergie éolienne est-elle un atout en hiver?",
    Theme: "Éolienne",
    Explanation:
      "En hiver, l’énergie hydraulique et l’énergie solaire ont l’inconvénient de produire moins d’électricité. L’énergie éolienne permet de remédier à cette situation, car elle génère deux tiers de sa production annuelle à cette saison. Selon les calculs de Suisse Eole, l’Association suisse pour l’énergie éolienne, il serait même possible de couvrir 20% des besoins en électricité de la Suisse en hiver grâce à l’énergie éolienne, et ce sans émissions de CO<sub>2</sub>.",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  27: {
    Id: "rechMJvR7EryJxLy8",
    Quiz: 27,
    Question: "Quel autre nom désigne une éolienne?",
    Theme: "Éolienne",
    Explanation:
      "Les éoliennes produisant de l'électricité sont appelées aérogénérateurs.",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  28: {
    Id: "recqXrqB5jN5UITNm",
    Quiz: 28,
    Question: "Où se situe le premier parc éolien vaudois?",
    Theme: "Éolienne",
    Explanation:
      "Le premier parc éolien vaudois a été construit à Sainte-Croix et a été mis en service en novembre 2023 par Romande Energie.",
    Intermediar: "video",
    ConsigneIntermediar: "Regarde la vidéo...",
    ConsigneURL: "https://player.vimeo.com/video/889838040?h=2ee7d07e55",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  29: {
    Id: "recgzptC8sCyzFcmI",
    Quiz: 29,
    Question: "Quelle est la durée de vie moyenne d'une éolienne?",
    Theme: "Éolienne",
    Explanation:
      "Une éolienne est performante pendant environ 25 ans. Elles sont totalement démontables et 90% de leurs matériaux sont recyclables!",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  30: {
    Id: "recgTh9UZhjCkZuCu",
    Quiz: 30,
    Question:
      "Le savais-tu? Une éolienne produira, au cours de sa vie, au moins 40 fois l’énergie nécessaire à sa construction. L'éolien a un meilleur éco-bilan que…",
    Theme: "Éolienne",
    Explanation:
      "L’éolien est l’un des moyens de production les plus respectueux de l’environnement en matière d’éco-bilan global, juste après l’hydraulique et l’incinération des déchets (selon l'OFEN – Office fédéral de l'énergie).",
    Images: [
      "assets/images/wind-1-2.jpg",
      "assets/images/wind-1-3.jpg",
      "assets/images/wind-1-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/pK3NENvvhlBVVDde5wIXNQ/jjl_ozVTSNnbB51ispTXfqodwmhLeIkcUhxOW0eYw555GurG7sYv42fkViH9-iNZc7TyPIowsR5ituSwLS0SxK9p7xBhVEfoqfM7pSk6if-YKPTYxoVAZlaqlPaywyaheS-QCKk8leVD2rTd7zsNZg/KoQFu7U0U_X2FymiLNQxxiCvt3mLtbo34fupWqnzlVE",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/6orsOqPm6-EiUSbpnXQzMQ/k-Ar6d-fE9C8a2YHsrdTbo49HPMFcpYFOzi9gwCIa4OkMj02k45rbfz1FUDj5RRKDX46N0MgAU-z6DVwqK-y6UGFt0ez-SrBwOZa8_776nGijTSl05p7oAW6udjZ20vIZ0Tqw5RU6ETj1ZbTqdsAew/tj-qGzx0_ju9NqIDAaA2s8Gzd6Wa_h4afvgMW_gc-9g",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/qoLktAAWJ711kBFLYYCLsg/YKRfD1TId23THdPZXLP7TmMTBdpj6JFZ09gLnewmijINBBsXeWf9vdJKgS5mGiRAaC0vQQn4PEP7HwT0o5NFDdKbkmyH246OOqdpjIhIvAghiiZ71Ia5_FYWe2p7bfzVzlfnkRn6sQG89xQ0K77wAg/cvc8OQxYOnmoKnMd1tainNmLzv0aFFUvMSePKSV-gCg",
    ],
  },
  31: {
    Id: "recT880hlUnQBRSDV",
    Quiz: 31,
    Question:
      "Pourquoi utilise-t-on encore autant les énergies fossiles (= 80% de l'énergie consommée) dans le monde?",
    Theme: "Fossiles",
    Explanation:
      "Malheureusement, le pétrole, le charbon et le gaz naturel sont des sources d'énergie très pratiques à utiliser et très rentables. Seul hic: l'utilisation massive de ces énergies fossiles pollue gravement notre planète! Ces ressources ne sont, de plus, pas illimitées. ",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  32: {
    Id: "recLxz8UtdBUzLSJG",
    Quiz: 32,
    Question:
      "Le savais-tu? Il y a 2'500 ans, les Chinois ont découvert les propriétés du gaz naturel. Comment appelaient-ils ces gisements de gaz?",
    Theme: "Fossiles",
    Explanation:
      "Les «puits de feu» ont été découverts en creusant des trous pour trouver de la saumure (du sel mélangé à de l’eau). Les Chinois transportaient ensuite le gaz dans des tubes de bambou et s’en servaient pour l’éclairage.",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  33: {
    Id: "recHzM0dtBQtYAPAn",
    Quiz: 33,
    Question:
      "Quand le charbon minéral que nous utilisons aujourd'hui s'est-il formé? ",
    Theme: "Fossiles",
    Explanation:
      "Comme le pétrole et le gaz naturel, le charbon est une énergie fossile. La genèse de sa formation a débuté il y a plus de 300 millions d'années, par la transformation de matière organique végétale. ",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  34: {
    Id: "recfoxPtQyXHUmYvL",
    Quiz: 34,
    Question: "Qu'est-ce qu'un derrick?",
    Theme: "Fossiles",
    Explanation:
      "Le derrick est une tour qui sert à creuser le sol pour aller chercher le pétrole. Il soutient de très grandes tiges creuses qui attaquent la roche. De la boue est injectée sous le sol, et lorsqu’elle remonte, elle emporte les morceaux de roche. Ainsi, le trou devient toujours plus grand et plus profond. Lorsqu’il atteint le gisement de pétrole, il n’y a plus qu’à pomper!",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  35: {
    Id: "recK9a4765wr6Vm3u",
    Quiz: 35,
    Question:
      "Depuis quand utilise-t-on massivement les énergies fossiles pour produire de l'énergie?",
    Theme: "Fossiles",
    Explanation:
      "Cela fait moins de 200 ans que l'humanité utilise massivement les énergies fossiles! Pendant longtemps, ces sources étaient peu connues et l'on ne savait pas s'en servir. Parfois, on tombait sur un gisement par hasard en creusant un puits et on se servait alors du pétrole pour alimenter des lampes.",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  36: {
    Id: "recQfoDUKJv2BdYbo",
    Quiz: 36,
    Question:
      "À l'échelle mondiale, quel est le pourcentage d'émissions de gaz à effet de serre produite par l'usage des énergies fossiles?",
    Theme: "Fossiles",
    Explanation:
      "Les énergies fossiles sont responsables de 78% des émissions de CO<sub>2</sub> dans le monde. C'est pour cette raison qu'on cherche à développer les énergies renouvelables et à réduire notre consommation énergétique (selon le GIEC – Groupe d'experts intergouvernemental sur l'évolution du climat).",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  37: {
    Id: "recReP26EIFy8wN8r",
    Quiz: 37,
    Question:
      "Pourquoi appelons-nous énergies «fossiles», les énergies produites à partir de charbon, de pétrole et de gaz naturel?",
    Theme: "Fossiles",
    Explanation:
      "Les énergies fossiles sont issues de gisements souterrains, formés par la décomposition de matières vivantes (p. ex. plantes et animaux). On utilise le terme fossile, car il s'agit d'une décomposition «très lente», sur plusieurs centaines de millions d'années.",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  38: {
    Id: "recJiUGPeLpSKIN5l",
    Quiz: 38,
    Question:
      "Quelle est la source d'énergie fossile la plus utilisée dans le monde? ",
    Theme: "Fossiles",
    Explanation:
      "L'énergie fossile la plus utilisée dans le monde est le pétrole (31%), suivie du charbon (27%) et du gaz naturel (23%).",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  39: {
    Id: "reczSJaHfW9K9hwZz",
    Quiz: 39,
    Question:
      "Pourquoi les énergies fossiles sont-elles grandement responsables du dérèglement climatique?",
    Theme: "Fossiles",
    Explanation:
      "Les grandes quantités de CO<sub>2</sub> libérées par la combustion des énergies fossiles «emprisonnent» la chaleur dans notre atmosphère, ce qui provoque le dérèglement climatique.",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  40: {
    Id: "recAZUsiBWeW1uCsb",
    Quiz: 40,
    Question: "Laquelle de ces trois affirmations est-elle correcte?",
    Theme: "Fossiles",
    Explanation:
      "Les énergies fossiles (pétrole, gaz naturel et charbon) peuvent être facilement stockées et transportées, ce qui en fait des sources d’énergie pratiques à utiliser. Mais elles présentent deux très gros inconvénients:\n1) En brûlant, elles dégagent beaucoup de particules qui polluent l’atmosphère, dont le fameux dioxyde de carbone (CO<sub>2</sub>); 2) Leur quantité sur Terre est limitée.",
    Images: [
      "assets/images/fossil-1-3.jpg",
      "assets/images/fossil-1-2.jpg",
      "assets/images/Q11.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/jGODidw7Gpsi34ZQp2EU1w/CqKKub6VtsyLX5OpHMUnlWC-qsOQINiC_0NFyU4GOFfNerdwQ64pecw148RNGot5gAuWBh6MnEVD9MtwaG4eJ5UGdT0hMK6XZyFzVeNfaHauc6rxIT354EDZvC5ZcIZZD6aFq-o_XSZiQq7Jbj6dGA/yPd-tJMZPEJDJxLrptXKcj0I3kUPJAz6PwzH64PQ6Ss",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XynqgJxVhNtNi-xyOogUFw/PwlxodbMk0NAW2s_a-aQKqF4A2ypQo6GH4CUhFE284Dz97NL_S9XaJryjmUYf9_YG8HS4J1DxeqQ9ja2hPTm9EcTTJwNMHnGOFyew86f1KOlNsq4BUcakk3uz6QvAs8bwa0L481yeZLjGV9NlNr6tw/ULwfkSn5KuwKbDHDBS5KFnxLorZvlvQM8zeO64URQnQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Hzx4TYYVyZMKmlukbt3g3A/xBeYyPtBkdzRoULqHe8xgtrT0wbz5oYhzbFDhfkemxEPuaXy-YH1U0-bymUr0_a7dD0VBx1LB53ARMhG0-PkSSxsYpJH6O2dUuzziraIncrP4B0pLyhL1_KV5w0njsiCmVfjcvJrGWi4juU9qwOCLg/QQXXv5ryU1EGotMiYcb0PXsMnzho_fC34GlcTkULqO8",
    ],
  },
  41: {
    Id: "recmc1XWbo516Vnu3",
    Quiz: 41,
    Question:
      "Charade! Mon 1<sup>er</sup> est la deuxième note de la gamme, mon 2<sup>e</sup> est le nom que l’on donne à un phénomène circulaire, comme le parcours de l’eau dans ses différents états (liquide-solide-vapeur), mon 3<sup>e</sup> est la durée qui s’est écoulée depuis notre naissance. Mon tout est...",
    Theme: "Général",
    Explanation:
      "Le recyclage (ré-cycle-âge) des déchets permet de diminuer la pollution et d'économiser de l'énergie. Les Suisses sont les champions du monde du recyclage! 0% des déchets urbains sont stockés dans des décharges: 50% sont recyclés et 50% sont brûlés et transformés en énergie.",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  42: {
    Id: "reclvnfM22YgTLiPO",
    Quiz: 42,
    Question:
      "Charade! Mon 1<sup>er</sup> est une exclamation espagnole que l'on crie lors d'un spectacle de flamenco, mon 2<sup>e</sup> est le contraire de bas, mon 3<sup>e</sup> est le titre de noblesse le plus élevé après celui de prince. Mon tout sert à…",
    Theme: "Général",
    Explanation:
      "Un oléoduc (olé-haut-duc) permet de transporter le pétrole depuis le lieu de son extraction. Il s'agit d'un grand tube (conduite) sur la terre ou sous l’eau. Pour le gaz, on appelle ce dispositif un gazoduc.",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  43: {
    Id: "recP7kLalD2CVWqC3",
    Quiz: 43,
    Question: "Quel est le nom de l'unité de mesure de l'énergie? ",
    Theme: "Général",
    Explanation:
      "L'unité de base de l'énergie est le joule (J), du nom du physicien James Precott Joule. Le Becquerel (Bq), quant à lui, est l'unité de mesure de la radioactivité d'un corps, du nom du physicien français Henri Becquerel. Et le watt (W) est l'unité de mesure de la puissance électrique. Un watt équivaut à un joule par seconde. Le nom «watt» rend hommage à l'ingénieur écossais James Watt.",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  44: {
    Id: "recagWVK6LgN3ocof",
    Quiz: 44,
    Question: "Laquelle de ces trois affirmations est-elle correcte?",
    Theme: "Général",
    Explanation:
      "L'énergie ne peut ni être créée ni être détruite. Elle peut seulement se transformer et passer d'une forme à une autre. La quantité totale d'énergie dans l'univers est toujours la même.",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  45: {
    Id: "recPANn0LFzZUJyfF",
    Quiz: 45,
    Question:
      "En 2017, le peuple suisse a voté pour la «Stratégie énergétique 2050». Laquelle de ces trois listes de mesures est-elle totalement juste?",
    Theme: "Général",
    Explanation:
      "Les actions décrites dans la première réponse font partie de la «Stratégie énergétique 2050». Celles-ci visent à nous rendre moins dépendants des importations d'énergies fossiles (celles-ci représentent plus de 80% de la consommation énergétique dans le monde!), construire (ou rénover) des bâtiments et fabriquer des objets qui demandent moins d'énergie. Nous pourrons ainsi limiter nos émissions de gaz à effet de serre et consommer moins et mieux.",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  46: {
    Id: "recW1ohs3o5R7k4BQ",
    Quiz: 46,
    Question:
      "En juin 2023, la loi sur le climat a été votée. Quel objectif fixe-t-elle?",
    Theme: "Général",
    Explanation:
      "La loi climat confirme l’ambition de la Suisse à réduire ses émissions de CO<sub>2</sub> afin d’être neutre en 2050. Pour y arriver, il est par exemple prévu de remplacer les chauffages ayant un fort impact environnemental et que la Confédération soutienne financièrement les entreprises investissant dans des technologies respectueuses du climat.",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  47: {
    Id: "rec2qt5cEJ8AmIAG0",
    Quiz: 47,
    Question:
      "Laquelle de ces énergies n'est pas considérée comme une énergie renouvelable ? ",
    Theme: "Général",
    Explanation:
      "Les rayons du soleil et les calories que l'on consomme sont considérés comme «renouvelables». Le nucléaire, quant à lui, utilise l'uranium qui est une ressource limitée.",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  48: {
    Id: "recS6lReerPQ5K7M1",
    Quiz: 48,
    Question: "Lequel de ces gestes est favorable à la biodiversité?",
    Theme: "Général",
    Explanation:
      "Installer des massifs de plantes aromatiques pour les insectes pollinisateurs, aménager une petite mare pour les libellules ou encore un nichoir pour les oiseaux sont des petites astuces pour favoriser et protéger la biodiversité!",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  49: {
    Id: "recFMNpZfAmVGZzy0",
    Quiz: 49,
    Question:
      "La transition énergétique signifie que l'on transforme notre système de production d'énergie pour…",
    Theme: "Général",
    Explanation:
      "La transition énergétique consiste à abandonner les combustibles fossiles au profit d'énergies renouvelables: l'énergie solaire, l'énergie éolienne, l'énergie hydraulique, la biomasse, les énergies marines, l'énergie géothermique.",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  50: {
    Id: "recBbBW9ARiyywftV",
    Quiz: 50,
    Question: "Qu'est-ce que la biodiversité?",
    Theme: "Général",
    Explanation:
      "La biodiversité, c’est la diversité de toutes les formes de vie telles que les baleines, les arbres, les fleurs, les oiseaux… ou encore les insectes, les microbes, les champignons, et même nous, les êtres humains! ",
    Images: [
      "assets/images/scotty-turner-dVLq8y3NgY4-unsplash-web.jpg",
      "assets/images/general-1-2.jpg",
      "assets/images/_f1_4345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/Wy9no73uZdJvdK5GH2lDuw/3jXEtDN2XgtuN4UeFkRHF8qThtdb2Kf7grVe49EqD-vf6F_DBND7Jk3-3W5ZbmT4RRVgwqtp7JDehFV837tD1yl1GcAyzZyynmzTZGD9XEtbF-v-kDba_dQSBn5cS45nQJoPdrs8o1D6Ohb2YEoLJg/Ve4v_zqcbOvdmJibWFqCXmvwc-f1mY349jj3-Nf0t0A",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/mfT-hO9FYFkpWZ0Ppi4LDw/d7tSBsGjMlmNW-YKeN_uF73fjX8ZB7fM1omvJeMmD2B251pqfMjBFNUN3DLQm0Qz7AviNq8O9sgkBdvQYOEAhoECI5_FWnDkigDCjqOrKcdoZA3-Grajvg04iCpg4lCgATCbzeibAJI9c96BC3s5Eg/rA2SzLP8TIQK4VKsxi9xxURnOzI4NnpervpN-BlFIjU",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/7drP18DJnk0fSVH8b43YHQ/H6q0-ahg5uD0q85RG3r9Alg_GYx_HIFy7Ynq--hbfJvzm-ctGyJBY0-5pJY2vzM4j-S0knDOinuC98qEpsTNCn4tdlQJIH0WflQWjYKjnrpqlQltTAj-GeIMa5GzdbJbRRf6hKbIups_U8rvHhz3AQ/Y0zRr1ri7cNEBbCwVHm_3tLIcG_RKbTlVwXgcMbuuKc",
    ],
  },
  51: {
    Id: "recK7DBKdIPzexnsS",
    Quiz: 51,
    Question:
      "Reconnais-tu l'installation qui figure sur l'image que tu viens de voir?",
    Theme: "Géothermique",
    Explanation:
      "Il s'agit d'une pompe à chaleur! Le principe? On envoie un liquide dans un puits (entre 80 et 400 mètres de profondeur) pour le réchauffer au contact du sous-sol naturellement chaud; il est ensuite pompé pour être remonté à la surface. Sa chaleur est ensuite valorisée par une pompe à chaleur qui permet d'élever encore sa température. Cela permet de chauffer les radiateurs d'une maison, et l'eau sanitaire (douche, robinets).",
    Intermediar: "photo",
    ConsigneIntermediar: "Regarde bien cette photo!",
    ConsigneImagePath: "assets/images/geo-1-Q.jpg",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  52: {
    Id: "recq9vusfGm05ZoEu",
    Quiz: 52,
    Question:
      "Charade! Mon 1<sup>er</sup> est enseigné à l’école, mon 2<sup>e</sup> est notre planète, mon 3<sup>e</sup> est la troisième note de la gamme, mon 4<sup>e</sup> est ce que le chien remue quand il est content. Mon tout est…",
    Theme: "Géothermique",
    Explanation:
      "L'énergie géothermique (géo-terre-mi-queue) utilise la chaleur naturellement présente dans le sous-sol de notre planète pour chauffer nos maisons et produire de l'électricité.",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  53: {
    Id: "recNGsZPIPlZdfI2i",
    Quiz: 53,
    Question:
      "Combien y'a-t-il de types d'installations pour exploiter l'énergie géothermique?",
    Theme: "Géothermique",
    Explanation:
      "Il existe trois types d'installations pour exploiter l'énergie géothermique: les pompes à chaleur, les installations hydrothermales, les installations pétrothermales.",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  54: {
    Id: "recWnUE4ZLgiUTq5s",
    Quiz: 54,
    Question: "Laquelle de ces affirmations est correcte?",
    Theme: "Géothermique",
    Explanation:
      "La chaleur naturellement présente dans le sous‑sol de notre planète représente une formidable source d’énergie. Plus on creuse profondément, plus on atteint des températures élevées. La géothermie utilise cette chaleur pour le chauffage et la production d’électricité.",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  55: {
    Id: "recxoHucpIi2lY9CM",
    Quiz: 55,
    Question:
      "Jusqu'à quelle profondeur les forages géothermiques peuvent-ils aller pour récupérer la chaleur du cœur de la Terre?",
    Theme: "Géothermique",
    Explanation:
      "Les forages peuvent aller jusqu'à 5 km! Les pompes à chaleur utilisent la géothermie de surface pour le chauffage en captant la chaleur souterraine peu profonde (de 80 à 400 mètres), où la température reste inférieure à 30°C. À moins de 3’000 mètres, on parle de géothermie de moyenne profondeur. L’eau souterraine atteint alors 50 à 70°C et est utilisée pour le chauffage. Les installations pétrothermales de géothermie profonde creusent des puits de 3’000 à 5’000 mètres, afin d’installer des sondes qui traversent de la roche à très haute température. On y envoie ensuite du liquide qui, au contact de cette roche, remonte à plus de 100°C.",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  56: {
    Id: "recdwbKWsWggdQ7mu",
    Quiz: 56,
    Question: "Quelle est la source de la géothermie?",
    Theme: "Géothermique",
    Explanation:
      "L'énergie géothermique désigne l'énergie stockée sous forme de chaleur dans le sous-sol. À environ 15 mètres de profondeur déjà, la température du sol est constante toute l'année. En Suisse, de manière générale, la température dans le sol augmente d'environ 30°C par kilomètre de profondeur.",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  57: {
    Id: "recheBJFW4gpbhfia",
    Quiz: 57,
    Question: "Quelle température fait-il à 5'000 mètres de profondeur?",
    Theme: "Géothermique",
    Explanation:
      "À 5’000 mètres de profondeur, la température avoisine les 150°C, c'est la raison pour laquelle on creuse des puits pour installer des sondes entre 3'000 et 5'000 mètres afin d'avoir le plus de chaleur possible!",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  58: {
    Id: "recRmfT0ccBRKBqm4",
    Quiz: 58,
    Question:
      "Quel type d'installation géothermique est le plus utilisé en Suisse?",
    Theme: "Géothermique",
    Explanation:
      "Le mode d'exploitation le plus connu de l'énergie géothermique est la pompe à chaleur. C'est un appareil de chauffage pour maison individuelle qui capte la chaleur contenue dans le sol ou dans des nappes phréatiques peu profondes.",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  59: {
    Id: "recaKdw9kUL7dWOYQ",
    Quiz: 59,
    Question:
      "Parmi les trois propositions, quel est le bon signe distinctif de la géothermie?",
    Theme: "Géothermique",
    Explanation:
      "Encore à ses prémices en Suisse, la géothermie mérite cependant d’être développée. En 2023, il existe une trentaine de projets géothermiques, dont trois de géothermie profonde (qui atteignent des sources souterraines situées entre -3'000 et -5'000 mètres de profondeur).",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  60: {
    Id: "rechxzXKq9XaWugcp",
    Quiz: 60,
    Question: "Laquelle de ces affirmations est-elle fausse?",
    Theme: "Géothermique",
    Explanation:
      "Le mot géothermie vient du grec et signifie «chaleur de la Terre». La géothermie utilise la chaleur du sous‑sol de notre planète pour le chauffage et la production d’électricité.",
    Images: [
      "assets/images/job-savelsberg-bg3Nob3opiQ-unsplash.jpg",
      "assets/images/geothermie-lavey-web.jpg",
      "assets/images/geo-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/A-uN9ssBZdR7ps0XqHUh4Q/s7a6GaRwgyKaUDweLPBxbJ3LKSumShvFGQ-KI5q-dDB4QPay9RbcBuRSGm9oEdbd-E8EAz-Pp1Ukg7nCnZ_JwhG-TVHMJNAnRwGVVAa7avPeBs958KUzuXydx6pQb5Xe6phpw0AOkCv1aGori9h1rg/UvlZJVM4X1jHkG_B_g6ZUs7dT68jUyDypOnMFqNMGYI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/D669yz-NNbHMdILJ4-ngCw/SF8I8uUmX6ylrv1BrmYpJ2wZzTesL6JskRyLtPNdXDWYBkb6oEXYm1iNtobwjImlnGtq78V6i0UDii94SPVhJ9MjPvmtfg-gspsTEpddwD_JZ0puHsQacnDN1R0LMddG0ucF7FtdiwKtLasty9uP2g/tgIj495LDFrxHG2fdhue2FyzN7Kkd6_dP1rBQsNRQy0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/k1H5YJK7y40PwZ7PHFTlqQ/S-O27-63vt_HOdwajcdkSB4gQoapT1uEKuixbSIpgkStZ_fYZPZtoQRqSNJNYni7uFxFLQIajddOKURx7GgHA7TQeTzMIaA_3_9qYGmcSm0XA84ECmLuwfW3A17JQb9Y3iDWT4RhH49EIwwwvnrtpw/6-8d1H29vdOcLFGZ9dOVOlm1eUkwBNia4BOsekFSq48",
    ],
  },
  61: {
    Id: "recxMo3SAgDH1D6zk",
    Quiz: 61,
    Question:
      "L'énergie hydraulique est l'un des modes de production d'électricité les plus propres et efficaces! Pourquoi, au juste?",
    Theme: "Hydraulique",
    Explanation:
      "L’énergie hydraulique fonctionne grâce au mouvement de l’eau, qui fait tourner une turbine et produit de l’électricité. Ce mode de production ne génère ni déchets ni CO<sub>2</sub>, car il s’appuie sur une ressource puissante qui n’a pas besoin d’être transformée: l’eau passe à travers une centrale et en ressort intacte!",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  62: {
    Id: "rec4GYC8IpKb2e0ML",
    Quiz: 62,
    Question:
      "Quelle est la différence entre les installations au fil de l'eau et les petites centrales hydrauliques? ",
    Theme: "Hydraulique",
    Explanation:
      "Les installations au fil de l'eau traversent des fleuves et sont complétées par des ponts où se trouvent les turbines. Dans ces installations, c’est le débit du cours d’eau qui détermine la quantité d’énergie générée.\nLes petites centrales hydrauliques (moins de 10 mégawatts), elles, se situent le long des rivières. L’installation «emprunte» de l’eau à la rivière en la faisant passer par une conduite pour l’amener jusqu’à une turbine qui va produire de l’électricité. Puis l’eau est rendue, intacte, à la rivière.",
    Intermediar: "video",
    ConsigneIntermediar: "Regarde la vidéo...",
    ConsigneURL: "https://player.vimeo.com/video/425580819",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  63: {
    Id: "rec6kdQvaaENCEf6p",
    Quiz: 63,
    Question: "L'installation hydraulique que tu vois est…",
    Theme: "Hydraulique",
    Explanation:
      "Romande Energie exploite trois centrales hydrauliques au fil de l’eau sur la rivière de l’Orbe. La plus en amont est la centrale de la Dernier, à Vallorbe. Quelques kilomètres plus bas, à proximité de la commune de Ballaigues, se trouvent le barrage du Day, retenant plus de 700’000 m<sup>3</sup> d’eau dans le lac du Miroir, puis la centrale des Clées, la plus puissante des centrales hydrauliques de Romande Energie, construite en 1955. Finalement, en amont d’Orbe se trouve la centrale de Montcherand. C'est celle que l'on voit sur ces images: elle a un bassin de compensation en amont qui permet de réduire les variations de débit en provenance de la centrale des Clées.",
    Intermediar: "video",
    ConsigneIntermediar: "Regarde la vidéo...",
    ConsigneURL: "https://player.vimeo.com/video/881987789?h=49bb2a2244",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  64: {
    Id: "recEajPePSxuIbx78",
    Quiz: 64,
    Question: "À quoi sert un barrage hydraulique? ",
    Theme: "Hydraulique",
    Explanation:
      "Un barrage, c’est un mur construit dans la vallée d’une rivière. L’eau s’accumule derrière ce mur et forme un lac. Lorsque l’on veut produire de l’électricité, on ouvre une vanne (un gros robinet), et l’eau qui s’écoule dans la conduite entraîne la roue de la turbine située bien plus bas. En montagne, ces installations ont l’avantage de disposer de grandes chutes. Et, plus la différence de hauteur entre la surface du lac (l’endroit où se trouve l’eau) et les turbines est grande, plus la pression est élevée et plus l’électricité produite est importante.",
    Intermediar: "video",
    ConsigneIntermediar: "Regarde la vidéo...",
    ConsigneURL: "https://player.vimeo.com/video/881986950?h=a2243aee43",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  65: {
    Id: "recH868qlVMVI6KKm",
    Quiz: 65,
    Question: "Cette turbine se trouve…",
    Theme: "Hydraulique",
    Explanation:
      "Il s'agit d'une turbine installée à la centrale des Clées, la plus grande installation hydraulique de Romande Energie!",
    Intermediar: "photo",
    ConsigneIntermediar: "Regarde bien cette photo!",
    ConsigneImagePath:
      "assets/images/RE-CLEES-TURBINE-VALENTIN-FLAURAUD-WEB-CREDIT.jpg",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  66: {
    Id: "recwcijdznLunUqbh",
    Quiz: 66,
    Question: "Quel est le barrage le plus haut de Suisse?",
    Theme: "Hydraulique",
    Explanation:
      "La Grande Dixence est le barrage le plus haut de Suisse et la hauteur de son mur est de 285 mètres! Son poids, environ 15 millions de tonnes, le rend plus lourd que la grande pyramide de Khéops. À sa base, le barrage a une épaisseur de 200 mètres. Au sommet, il s’affine pour atteindre 15 mètres. ",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  67: {
    Id: "recxIeM6TJESLARS3",
    Quiz: 67,
    Question:
      "Pourquoi la centrale hydraulique de Nant de Drance est-elle considérée comme une batterie géante pour stocker l'énergie?",
    Theme: "Hydraulique",
    Explanation:
      "Grâce au pompage-turbinage, la centrale hydroélectrique de Nant de Dance, dans la vallée du Trient (VS), utilise la différence de niveau entre les deux lacs d'Émosson pour, suivant les besoins, produire de l'énergie ou absorber des pics de production. Comme toutes les centrales de transfert d'énergie par pompage, celle du Nant de Drance a été construite dans un but de stockage de l'énergie.",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  68: {
    Id: "recTknhmCQrshZffX",
    Quiz: 68,
    Question:
      "Dans le monde, quel pourcentage d'électricité est produit à partir d'énergie hydraulique?",
    Theme: "Hydraulique",
    Explanation:
      "Les centrales hydroélectriques produisent 4'408 TWh, soit environ 15% de la production mondiale d’électricité, selon les chiffres établis en 2022. Cela représente la moitié de la production d'électricité renouvelable produite chaque année sur la planète!",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  69: {
    Id: "recnfWOhav0naeIo1",
    Quiz: 69,
    Question: "Où se trouve le barrage le plus haut du monde?",
    Theme: "Hydraulique",
    Explanation:
      "En 2023, le plus haut barrage au monde est le barrage de Jinping en Chine, avec une hauteur de 305 mètres!",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  70: {
    Id: "recQp9Cwvb39SbbPh",
    Quiz: 70,
    Question: "Quel est le rendement de l'énergie hydraulique?",
    Theme: "Hydraulique",
    Explanation:
      "Le rendement énergétique est le rapport entre l'énergie produite et celle consommée lors d'un processus. L'énergie hydraulique a un excellent rendement, autour des 90%, ce qui en fait une ressource très intéressante. Étant l'une des énergies les plus anciennement exploitées, les spécialistes la maîtrisent pleinement et en ont fait une énergie avec une bonne performance en plus d'avoir un impact environnemental assez faible.",
    Images: [
      "assets/images/hydro-1-3.jpg",
      "assets/images/Q06-RE.jpg",
      "assets/images/_f1_6345-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/vOorwsME2vocGpkwfy0D3w/bxAQzpUFT5zlaIvxbOVUeIzifD9iebDshWLGflFaaN6IJbU7_inCc_b6xrXs7PrzvJYrzDvB_F1p5fmhWMl5qYroL447ZOJ-bXxFBQjJ_-VT-V8jrhto6Q6xZR6U_G43KUxeLooZoNGDKRPpJbBJmQ/KYW0gHJWh9q9tV6rTNVZyHJSDoC7HzuVQSQr30UvwFw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/f2Evy0NgMMZLM2Hc7oJtYQ/8EwhGJGtGnk7bdhFfoVGahr3npX_5TmJ7NcUgVnPaqEJ9GfH4BKP2e6dZfzaf0m1SLcSiH1Kn922YaA9qPGNMn1ZugFbzxKGOLbgoxwkY741G8skW9wBRHQgCf8w0cirfvuEXYBWjXXfLsn9QR6tIA/jLO9qu5UhzkXeR7WmrhZtfJkqChhzvTerpAaKM2Lsjc",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/V4nbn01KfzW-TTSsBZaG9w/7_UBJWM6rbE11-T151JyhPIfqg7AR5bHHMN3mcrilblJVkYgtsd7IRtJLPsp09X_Y78ooXbmiWYBtATKxsSPWyvvR48PGpWNaYk78oGiJo3BXKM7MooCckgoYGw8YcaZy_mVJyV7gP64_YQ1KaEa2g/dWJexf3n_silkjfiAG7BwCTwutM7HDx_fSHSxy4E--g",
    ],
  },
  71: {
    Id: "recrbxHCVdcypuJmV",
    Quiz: 71,
    Question:
      "La mer et l'océan sont de remarquables sources d'énergie! Mais, au fait, sais-tu pourquoi l'énergie marine est peu exploitée?",
    Theme: "Marines",
    Explanation:
      "La mer et l'océan restent difficiles à maîtriser, même si leur puissance énergétique est très grande. Le sel est aussi un frein, car il ronge les installations, ce qui, à la longue, peut créer des dégâts et nécessiter des remplacements fréquents de matériel.",
    Intermediar: "video",
    ConsigneIntermediar:
      "Regarde cette animation d'hydroliennes en action pour te «plonger» dans la question... ",
    ConsigneURL: "https://player.vimeo.com/video/425592045",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  72: {
    Id: "reccFnHgasZMNYwTS",
    Quiz: 72,
    Question:
      "Les usines marémotrices utilisent le mouvement de la marée pour actionner des turbines et produire de l'électricité. La marée est un phénomène naturel qui dépend... ",
    Theme: "Marines",
    Explanation:
      "Les marées sont dues à un phénomène naturel qu'on appelle la gravitation universelle. Dans l'univers, tous les corps s'attirent plus ou moins selon leur masse et la distance des uns par rapport aux autres. La Lune exerce une forte attraction sur la Terre, qui se déforme pour suivre son déplacement. C'est ce phénomène qui provoque les marées.",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  73: {
    Id: "recIXfp3lsMzT30CH",
    Quiz: 73,
    Question: "Comment fonctionnent les installations houlomotrices? ",
    Theme: "Marines",
    Explanation:
      "Les installations houlomotrices, qui prennent la forme de longs serpents de mer flottants, exploitent le mouvement des vagues et de la houle pour produire de l’électricité.",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  74: {
    Id: "rec3EmQjd31jbeMKi",
    Quiz: 74,
    Question: "Qu’est-ce que l'énergie osmotique?",
    Theme: "Marines",
    Explanation:
      "Grâce à sa teneur en sel, chaque mètre cube d'eau de mer a le potentiel de fournir près de 1 kWh. Les installations osmotiques exploitent ce magnifique potentiel en se plaçant aux endroits où l'eau douce des fleuves rencontre l'eau salée de la mer.",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  75: {
    Id: "recGx18KnXbiilpcH",
    Quiz: 75,
    Question: "Comment fonctionnent les hydroliennes?",
    Theme: "Marines",
    Explanation:
      "Les hydroliennes sont de grandes hélices sous‑marines qui fonctionnent comme des éoliennes. Elles tournent grâce aux courants marins. Ces courants font bouger d’immenses quantités d’eau sur de très grandes distances. Ils sont principalement provoqués par le vent, les différences de température, de densité et de salinité de l’eau, ainsi que par une force que l’on appelle «inertie». Comme il existe des régions particulièrement venteuses sur les continents, il y a des régions sous‑marines où les courants sont plus forts et réguliers.",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  76: {
    Id: "recc17Xv5mrGFmtFK",
    Quiz: 76,
    Question: "Depuis quand utilise-t-on les énergies marines?",
    Theme: "Marines",
    Explanation:
      "Comme beaucoup d’énergies renouvelables, celle de la mer semble avoir été exploitée depuis l’Antiquité. Mais on trouve surtout des restes de moulins à marée qui datent du Moyen-Âge dans de nombreux pays européens: au Royaume-Uni, aux Pays-Bas, en Belgique, en Espagne et au Portugal par exemple. En France, c’est surtout en Bretagne que l’on peut observer d’anciens moulins à marées datant du 17<sup>e</sup> siècle.",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  77: {
    Id: "recWQ3rMVFQSdP0jK",
    Quiz: 77,
    Question:
      "Parmi ces installations, laquelle ne concerne pas les énergies marines?",
    Theme: "Marines",
    Explanation:
      "Les installations houlomotrices, qui prennent la forme de longs serpents de mer flottants, exploitent le mouvement des vagues et de la houle pour produire de l’électricité. Les hydroliennes sont de grandes hélices sous‑marines qui fonctionnent comme des éoliennes et qui tournent grâce aux courants marins. L'intrus était la centrale au fil de l'eau, qui utilise l'énergie hydraulique produite par la force du courant d'une rivière ou d'un fleuve!",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  78: {
    Id: "recmeNtPwsM1hIP0M",
    Quiz: 78,
    Question:
      "Combien d'installations d'énergies marines la Suisse possède-t-elle?",
    Theme: "Marines",
    Explanation:
      "Il n'existe aucune installation d'énergies marines en Suisse puisque le pays ne compte ni mers ni océans!",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  79: {
    Id: "recZ3l7lK5TX6BQXc",
    Quiz: 79,
    Question:
      "Dans quels pays se situent les deux plus grandes usines marémotrices? ",
    Theme: "Marines",
    Explanation:
      "La puissance de l’usine de la Rance (France) est de l’ordre de 240 MW. L’usine de la Rance est restée pendant 45 ans la centrale marémotrice la plus puissante du monde, jusqu’à la mise en service, en 2011, de l’usine de Sihwa Lake (Corée du Sud). Cette dernière a une puissance installée de 254 MW.",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  80: {
    Id: "recLPop4q1Lz4wzp9",
    Quiz: 80,
    Question: "Quel type d'installation permet de capter l'énergie des marées?",
    Theme: "Marines",
    Explanation:
      "Comme les moulins à marées d’hier, les usines marémotrices utilisent le mouvement de la marée pour actionner des turbines. Installées à l’endroit où un fleuve se jette dans la mer, ces usines ressemblent aux centrales hydrauliques au fil de l’eau. La différence, c’est que l’eau peut traverser ces installations dans les deux sens. À marée haute, le bassin de stockage se remplit. Lorsqu’il est plein, on ferme la vanne. À marée basse, on obtient une différence de hauteur d’eau importante, c’est le moment d’ouvrir la vanne: le mouvement de l’eau qui s’engouffre dans la mer actionne alors les turbines. Cela fonctionne de la même manière dans l’autre sens, lorsque l’eau de la mer est plus haute que l’eau retenue dans le bassin.",
    Images: [
      "assets/images/mer-2-2.jpg",
      "assets/images/mer-2-3.jpg",
      "assets/images/mer-2-1.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/9souq8Jb064X2u7zlvSNtg/zROU6j2nD5MZ_H0BHE9vr-_FH7iWQhwA7oPwVsH2fOnF2zrQDcpNxZU7q_eKEVjx6Yo-rMDTQkaV827c8hecZwGWJULfVRKxcj_LXTiPn0P4eqX2UVS4qr6mHkMpVAOR5TZ_M6ow7qCAcjFdg_4gJw/FcD-EMmf5jUfNq9NMwMdlGIapQGW3_2RO9jQv0dEl-0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/dFGlUl5QjLHQUxuk4k5rnQ/EqF7ePLXngKAVWEvVWtDa5vsU4iChLMrvZeSkZLFBwjHw12aVt8kkJ9X_zuk_amZWHDba5v2budUBldagXkYW0YXGnyqSLzvJ8cXG-bCpRMhqe_aGOmpuWTWO_-7xQZP_JjA7cb-pf3l2uwrJXJ1lA/7wvPJhdKRBVXmnsDYDiJMYRE5prvhChGljGWoGBJmWI",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/GnsPwSpdx2vhE1qn5uAySA/5_dY5cCtwjvOnNv3YgIWcEl0bF90l8f9_AasqtiEmNzOwgvdnTKejYqjEf93OCabEEgAz-yPnCC0Vl5zu7jNHeAC5xUmLVyVXGTyioj4bL-EU61ytoV-i33cQ9ltOsuJPgqHpJNgNr0t2ie_sM_ZuA/snlydF-PDF0zuwXAn_BB-y4VRJFmRZNWuJPnCwkttF0",
    ],
  },
  81: {
    Id: "rec1LoRbD4VHGwRu0",
    Quiz: 81,
    Question:
      "Toi aussi tu es une source d'énergie! Connais-tu les deux principales fonctions pour lesquelles nous utilisons notre énergie musculaire?",
    Theme: "Musculaire",
    Explanation:
      "L’énergie qu’utilise le corps humain sert à beaucoup de choses, mais on peut distinguer deux fonctions principales: maintenir une température constante du corps à 37°C et utiliser ses muscles. Le savais-tu? Dans notre corps, c'est notre cerveau qui utilise le plus d'énergie pour fonctionner! Mais comme notre cerveau n'est pas un muscle, on ne parle pas d'énergie musculaire.",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  82: {
    Id: "recavollVQjzhnoLL",
    Quiz: 82,
    Question:
      "Où les êtres humains trouvent-ils de l'énergie pour faire fonctionner leurs muscles?",
    Theme: "Musculaire",
    Explanation:
      "L’être humain trouve l’énergie pour vivre dans la nourriture, les aliments sont notre carburant! Cette énergie se répartit dans notre corps et notamment dans nos muscles. Pendant longtemps, l’humanité s’est développée à la seule force des muscles: ceux des femmes, des hommes et des animaux.",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  83: {
    Id: "rec3NfEGBi2bh9jAo",
    Quiz: 83,
    Question:
      "Peut-on utiliser l'énergie musculaire pour produire de l'électricité?",
    Theme: "Musculaire",
    Explanation:
      "Il est possible d'utiliser l'énergie musculaire pour produire de l'électricité! Par exemple, en 2007 dans un fitness à Hong Kong, des vélos d’entraînement et des tapis de course ont été installés avec des dispositifs qui récupèrent l'énergie pour éclairer les salles!",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  84: {
    Id: "recqwe0R4kwfviz5b",
    Quiz: 84,
    Question: "Au repos et sans effort musculaire particulier, un être humain…",
    Theme: "Musculaire",
    Explanation:
      "On peut comparer le corps humain à un moteur qui a besoin de carburant pour fonctionner. Ce carburant, c’est bien sûr la nourriture. Nous transformons l’énergie chimique des aliments en énergie thermique (chaleur) et en énergie mécanique. La puissance thermique, c’est la quantité de chaleur libérée par un corps humain, exprimée en watts. Pour un individu assis, au repos, elle est égale à 100 watts, soit l’équivalent de la puissance d’une ampoule à incandescence.",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  85: {
    Id: "recOoX6b7lQ1tYz3t",
    Quiz: 85,
    Question:
      "Complète cette définition: La propulsion humaine est un moyen d'assurer le déplacement de personnes ou d'objets physiques en utilisant…",
    Theme: "Musculaire",
    Explanation:
      "La bonne réponse est: l'énergie musculaire humaine. Les véhicules à propulsion humaine sont par exemple les vélos, les trottinettes, les pédalos, les paddles ou les bateaux à rames.",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  86: {
    Id: "rec4iHuxkrNgfmgkt",
    Quiz: 86,
    Question: "Un cycliste du Tour de France développe une puissance de…",
    Theme: "Musculaire",
    Explanation:
      "Pendant le Tour de France, les cyclistes produisent en moyenne entre 220 et 320 watts par étape (soit pendant trois à cinq heures). Un cycliste amateur peut difficilement tenir plus d’une heure à cette puissance.",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  87: {
    Id: "rec1BQ6hmniXlvqgG",
    Quiz: 87,
    Question:
      "Le savais-tu? Une personne adulte en bonne santé physique peut couramment produire…",
    Theme: "Musculaire",
    Explanation:
      "La bonne réponse est de 50 à 150 watts! Mais savais-tu que même au repos, nous consommons un petit peu d'énergie pour maintenir notre métabolisme? Notre cœur, par exemple, consomme en continu quelques watts de puissance pour faire circuler le sang dans nos veines.",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  88: {
    Id: "recBYDrv7svBrnJok",
    Quiz: 88,
    Question: "Lors de la contraction musculaire…",
    Theme: "Musculaire",
    Explanation:
      "Pendant un effort musculaire, le muscle a besoin d’énergie pour se contracter. Ce carburant provient d’une alimentation équilibrée, qui apporte à l'organisme l’ensemble des nutriments dont il a besoin. Lors de la contraction musculaire, le muscle va transformer l’énergie chimique en énergies mécanique et thermique.",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  89: {
    Id: "recaHwdro5xee4ZM5",
    Quiz: 89,
    Question: "Pourquoi parle-t-on de chevaux pour la puissance d’un moteur?",
    Theme: "Musculaire",
    Explanation:
      "Jusqu'à la fin du 18<sup>e</sup> siècle, les chevaux étaient le moyen le plus utilisé pour déplacer des charges et servaient aussi à actionner des machines, par exemple pour remonter l'eau des puits. Avec l'invention de la machine à vapeur, il a fallu établir une conversion entre la puissance de ce moteur et celle que l'on peut obtenir d'un cheval. Le «cheval-vapeur» a été défini comme la puissance développée par un cheval pour remonter de 1 mètre une masse de 75 kg en 1 seconde, ce qui correspond à env. 735 watts.",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  90: {
    Id: "recqr4Pmv6bZZ0gwC",
    Quiz: 90,
    Question: "Qu'est-ce qu'un animal de trait?",
    Theme: "Musculaire",
    Explanation:
      "La traction animale, bien que très ancienne, reste importante dans de nombreux pays. Grâce à l'énergie fournie par leurs muscles, les animaux sont une aide précieuse pour les travaux agricoles et les transports. ",
    Images: [
      "assets/images/jonathan-chng-BJrgqUKYx8M-unsplash.jpg",
      "assets/images/victor-freitas-KIzBvHNe7hY-unsplash-web.jpg",
      "assets/images/muscu-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/TJWp8bpEuz6GrY623_w_oQ/_NInweFQYeJMG1WneSM5pvQqf_vvaFdGzcvrKQqCIOjGEYis7EPAdNwd3pGwHNI4q9qtfJgDzPnfKO0_T2HKczGH6iNrf32qvmVeBY-xbULm5jbyJKT1JrnAVuPBC9IwmZWKHfURvh0KAXNnicxUVw/mkcyG2LUDXZMzHj6DhKs7NTBRdTf7tRwIMJ6UvcdbR0",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/w1c_SSXSf4m3PtBa7g96Yg/FYGDXAGS-8XPHLBS2JRZcnn_xGGTF6ySweEAJA6a6yxU3KH-ujLesPFebLyPQBat5cVgOyLRsRqomXkan2tI40Yj2XXpRu43_QeNHhT-XCE5PsXVozvSEz9OuRN2UiDDfZPGQcxOBTAsTzCy7kQG-A/hTKF2frryYW4Lpzhpp0KedYLTNx5W8kYjQOsW_TmDGw",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/8Zxhz8E54HwZytAQ-RD6rg/sA1FmvwIcXPU8d9rBP-wKtpLdBxkk6UpN9xYpzRqOUrTC6sAtRkh3AtpkJ1AmGvb_mT8thxvM_6jB4YMmeycSpU3gsDwr4zDG4u5tWoxBRnwDiXW1p9oQGIsFR5VC7jsQ6gyRYx0dYue94cDjrxYGQ/AQr7DoKrQnq8UL5NyG8DsJRWtFQml49eLILHLsB9cNg",
    ],
  },
  91: {
    Id: "recwIXvYqUYMzfoND",
    Quiz: 91,
    Question:
      "Le savais-tu? Sur le campus de l'EPFL à Ecublens se trouve le seul réacteur nucléaire de Suisse romande. Quel est son nom?",
    Theme: "Nucléaire",
    Explanation:
      "Crocus est un réacteur nucléaire expérimental, dit de puissance nulle. Il est destiné à l’enseignement et à la recherche en physique des réacteurs et en détection des rayonnements ionisants. Incroyable, non?!",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  92: {
    Id: "reciy5R9iDXLmBKo5",
    Quiz: 92,
    Question: "Quelle est la particularité de l'uranium?",
    Theme: "Nucléaire",
    Explanation:
      "L’uranium a la particularité d’être radioactif. Cela signifie que le noyau de ses atomes est instable et a tendance à se désintégrer. Ce phénomène naturel libère une très grande quantité d’énergie.",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  93: {
    Id: "rec7IlFrD2e4lvnL9",
    Quiz: 93,
    Question:
      "En Suisse, dans quelle commune a eu lieu un accident nucléaire à la fin des années 1960? ",
    Theme: "Nucléaire",
    Explanation:
      "À Lucens, dans le canton de Vaud, un important accident nucléaire s’est produit en 1969. Une installation, alors en phase expérimentale, rencontra un problème de refroidissement qui conduisit à une fusion partielle du cœur de la centrale. Cet accident, classé au niveau 4 sur 7, a entraîné une contamination radioactive dans la grotte qui abritait cette centrale. S’il n’a heureusement fait aucune victime, l’accident de Lucens est généralement reconnu comme l’un des dix accidents mondiaux les plus sérieux dans le domaine du nucléaire civil.",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  94: {
    Id: "recHZwV1IkFEL5m4B",
    Quiz: 94,
    Question:
      "Charade! Mon 1<sup>er</sup> correspond à la partie dorsale du cou, mon 2<sup>e</sup> est un liquide produit par les vaches, mon 3<sup>e</sup> est un fluide gazeux que respirent les êtres vivants. Mon tout est un type d'énergie…",
    Theme: "Nucléaire",
    Explanation:
      "Il s'agit de l'énergie nucléaire (nuque-lait-air)! L’humanité maîtrise l’énergie nucléaire depuis moins de 100 ans. Mais les recherches sur la radioactivité ont commencé à la fin du 19<sup>e</sup> siècle. Tu as sans doute entendu parler de Pierre et Marie Curie, d'Henri Becquerel, et aussi, d’Albert Einstein. Ces grands scientifiques ont permis de mieux comprendre le phénomène de la radioactivité.",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  95: {
    Id: "receOhCwwJAY2N9XB",
    Quiz: 95,
    Question:
      "Quelle est cette matière, source pour produire de l'énergie nucléaire?",
    Theme: "Nucléaire",
    Explanation:
      "L'uranium est un métal radioactif présent dans le sous-sol de la Terre. Avant de pouvoir l'utiliser comme combustible dans les réacteurs des centrales nucléaires, il faut l'extraire et le transformer.",
    Intermediar: "photo",
    ConsigneIntermediar: "Regarde bien cette photo!",
    ConsigneImagePath: "assets/images/Uranium-Creartive-Commons-WEB.jpg",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  96: {
    Id: "recH4icO1ojxeqYcr",
    Quiz: 96,
    Question:
      "Le savais-tu? En Suisse, l'énergie nucléaire représente environ…",
    Theme: "Nucléaire",
    Explanation:
      "Suite à l'accident de la centrale de Fukushima au Japon en 2011, le peuple suisse a voté pour que les centrales nucléaires ne soient pas remplacées à la fin de leur durée d'exploitation. Depuis la fermeture de Mühleberg fin 2019, la Suisse ne compte plus que quatre réacteurs: Beznau I et II, Gösgen et Leibstadt. Ils assurent 29% de la production électrique suisse. ",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  97: {
    Id: "recSGfNIfvQ47Lqq0",
    Quiz: 97,
    Question:
      "Depuis combien de temps utilisons-nous l'énergie nucléaire pour produire de l'électricité?",
    Theme: "Nucléaire",
    Explanation:
      "L’humanité maîtrise l’énergie nucléaire depuis moins de 100 ans. Mais les recherches sur la radioactivité ont commencé à la fin du 19<sup>e</sup> siècle. Tu as sans doute entendu parler de Pierre et Marie Curie. Et aussi, d’Albert Einstein. Ces grands scientifiques ont permis de mieux comprendre le phénomène de la radioactivité. C'est depuis les années 1950 que l’énergie nucléaire est utilisée pour produire de l’électricité.",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  98: {
    Id: "recUlPt5bLzg5PCDv",
    Quiz: 98,
    Question:
      "Charade! Mon 1<sup>er</sup> est le son que l’on produit pour prononcer la 21<sup>e</sup> lettre grecque φ, mon 2<sup>e</sup> est le nom d’une ville qui est aussi le chef-lieu du Valais, mon 3<sup>e</sup> est une partie du corps situé à l’arrière du cou, mon 4<sup>e</sup> est un liquide blanc comestible que produisent les vaches, mon 5<sup>e</sup> est un fluide gazeux que respire tous les êtres vivants. Mon tout est...",
    Theme: "Nucléaire",
    Explanation:
      "La fission nucléaire (phi-Sion nuque-lait-air) est engendrée par la séparation d’un noyau atomique lourd en deux noyaux atomiques plus légers. Le noyau de certains atomes est instable, il peut éclater en deux et libère ainsi de l’énergie, qui se transforme principalement en chaleur. C’est ce principe que l’on exploite dans les centrales nucléaires.",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  99: {
    Id: "receegl3XpJOc9zuj",
    Quiz: 99,
    Question:
      "Lequel de ces éléments n'est pas présent dans une centrale nucléaire?",
    Theme: "Nucléaire",
    Explanation: "Le digesteur est l'installation utilisée pour la biomasse!",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  100: {
    Id: "rectNHCSFlrhlShsA",
    Quiz: 100,
    Question: "Qu'est-ce que la radioactivité?",
    Theme: "Nucléaire",
    Explanation:
      "L’humanité maîtrise l’énergie nucléaire depuis moins de 100 ans. Mais les recherches sur la radioactivité ont commencé à la fin du 19<sup>e</sup> siècle. Tu as sans doute entendu parler de Pierre et Marie Curie. Et aussi, d’Albert Einstein. Ces grands scientifiques ont permis de mieux comprendre le phénomène de la radioactivité.",
    Images: [
      "assets/images/centrale-nucleaire-salle-de-controle_CMJN.jpg",
      "assets/images/Q25.jpg",
      "assets/images/Q15.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/XgdcQ6ZXWPhvk90S0gJDWQ/jEQNOrh-trwGbNmo6qRn34HnGnPmUuG0fXprib_HKbkpDCz9CwuS05j6Dj0a3KrTSWqcGL5YepgO2jHiur_PE87z4O2aAUBL2s4fxoMwi0rBzjSfK0mnRejOFYWaNrJEUm7WFS2OeUaP2hi6C-n4lw/g2qsyCHFnOdXZBg-krCsFbNUTErM0VVg0eSprIuILYY",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/bqC6BPKZdHzxwb-Jcm1Cog/kh9M4KeUEUF-yHuy2lpsdjUnsplVmIbOl6xxwjcNHDRtAp9B22pKk81mbQAIs-J6D-VovqYtOnyIboLVEuCvVJnpngqqXNvEPBwsxF00MR8Go6zZi4ieemJgbQq-jg_57bEBiHSmUqlukSo6fU32dg/IS91SDRrYNM5nLJIMmQxjYSUdatMmXHZyjfnuZ7hKBM",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/FMIY6AQZF1uHUudnLriApQ/COKwNuZraE9CcpCylfkIi4b5O7lKAor4T5EauObW95O-gbI9E_TbHv4RPWCuZfaFtH0Nif-WmNc0_BIZ6tjom-rbF--5KHYTE7N7IpzdP2lRm-x86JZXDY4J0PNH5d9uL_MzZBgQtRObzpLhtrLIJQ/Xb-JR9AJogalpsA8Nw3SHn0vGYJbAkqewblD4iVjGgs",
    ],
  },
  101: {
    Id: "reccaKkqtVKcwkh1U",
    Quiz: 101,
    Question:
      "Le savais-tu? Le premier parc solaire flottant a été mis en service en décembre 2019. Dans quel pays se trouve-t-il?",
    Theme: "Solaire",
    Explanation:
      "Cet incroyable parc solaire flottant en milieu alpin, une installation de Romande Energie, se trouve bien en Suisse, et plus précisément sur le lac des Toules (VS) à 1800 mètres d'altitude. Il se compose de 1400 panneaux solaires flottants, qui produisent 800'000 kWh/an.",
    Intermediar: "video",
    ConsigneIntermediar:
      "Lance la vidéo pour voir l'installation de cet incroyable parc solaire flottant.",
    ConsigneURL: "https://player.vimeo.com/video/889841862?h=b27f3f6e55",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/solar-1-2.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/zkRAhX5r63icCHwAeHIWKw/WoBW10I96o2eKshkN-m8gDwBstjM7IqxCQbmHs6p88p_ySABSnc4DleQbwgoylFmyv0onDDQOZO7nx8OLs4AeyQKlRRAmAGkGjEvRuGyYdNKyNbFH9rhhdt67isFsKk6mExJhre1Yo7k51tSnMi4_A/D7B_4ld7jWIb2TQRjkywAj_ZgWahX_Dk0HIvSta0Giw",
    ],
  },
  102: {
    Id: "recCPIcAYcgkMunuT",
    Quiz: 102,
    Question:
      "Comment s'appellent ces installations solaires équipées d'immenses miroirs paraboliques, sphériques ou plats?",
    Theme: "Solaire",
    Explanation:
      "Il s'agit de centrales solaires thermodynamiques. Leurs miroirs suivent la course du soleil et permettent de concentrer les rayons pour chauffer un liquide qui restera chaud même après le coucher du soleil. ",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/_F1_0884-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/LEJIJKublDCi98t9PYm2Hg/Kv0s3NB6d7i7QSTmQwcF6GAPN97rCqvuHDacyPdZoQLen5MJB8EB0vt6emsW76eadbFxYvVkfVx70g9Z9z8xMpm94SSMGOpna9HnLeY5jMbx_U6ixJIJwhiGiijatUnNnaQggQrdZWQC2RsYyXp86w/d9J_JD6w-GImFcDELqeRjehler_Nq8MdYRfBoPmfNmE",
    ],
  },
  103: {
    Id: "rectrezPFGq8iPcVY",
    Quiz: 103,
    Question:
      "De quelle matière principale sont composées les cellules des panneaux photovoltaïques?",
    Theme: "Solaire",
    Explanation:
      "Ce n'est que du sable! Les cellules photovoltaïques sont généralement composées de silicium. Ce matériau semi-conducteur permet de transférer l’énergie des photons qui composent la lumière, aux électrons. La chaleur est issue de l’agitation des microscopiques composants de la matière: les molécules et les atomes. L’électricité est l'effet provoqué par le déplacement des électrons. ",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/_F1_0884-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/LEJIJKublDCi98t9PYm2Hg/Kv0s3NB6d7i7QSTmQwcF6GAPN97rCqvuHDacyPdZoQLen5MJB8EB0vt6emsW76eadbFxYvVkfVx70g9Z9z8xMpm94SSMGOpna9HnLeY5jMbx_U6ixJIJwhiGiijatUnNnaQggQrdZWQC2RsYyXp86w/d9J_JD6w-GImFcDELqeRjehler_Nq8MdYRfBoPmfNmE",
    ],
  },
  104: {
    Id: "recNp88rmzdobrTaL",
    Quiz: 104,
    Question: "Quelle est la durée de vie d'une installation solaire? ",
    Theme: "Solaire",
    Explanation:
      "L'espérance de vie des panneaux solaires se situe entre 20 et 30 ans, et environ 95% de leurs composants peuvent être recyclés.",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/_F1_0884-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/LEJIJKublDCi98t9PYm2Hg/Kv0s3NB6d7i7QSTmQwcF6GAPN97rCqvuHDacyPdZoQLen5MJB8EB0vt6emsW76eadbFxYvVkfVx70g9Z9z8xMpm94SSMGOpna9HnLeY5jMbx_U6ixJIJwhiGiijatUnNnaQggQrdZWQC2RsYyXp86w/d9J_JD6w-GImFcDELqeRjehler_Nq8MdYRfBoPmfNmE",
    ],
  },
  105: {
    Id: "recM5F6t4fRMYnIKG",
    Quiz: 105,
    Question:
      "L’utilisation de la chaleur solaire au moyen d’une installation solaire thermique est une option intéressante pour la production...",
    Theme: "Solaire",
    Explanation:
      "Les installations solaires thermiques servent à produire de l’eau chaude ou comme appoint aux installations de chauffage. Pour ce faire, les capteurs solaires convertissent l’énergie du rayonnement solaire en chaleur. Une petite surface de capteurs installés en toiture (4 à 6 m<sup>2</sup>) et un accumulateur de taille réduite (450 litres) couvrent 60 à 70 % des besoins annuels d’eau chaude pour une famille de 4 personnes.",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/_F1_0884-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/LEJIJKublDCi98t9PYm2Hg/Kv0s3NB6d7i7QSTmQwcF6GAPN97rCqvuHDacyPdZoQLen5MJB8EB0vt6emsW76eadbFxYvVkfVx70g9Z9z8xMpm94SSMGOpna9HnLeY5jMbx_U6ixJIJwhiGiijatUnNnaQggQrdZWQC2RsYyXp86w/d9J_JD6w-GImFcDELqeRjehler_Nq8MdYRfBoPmfNmE",
    ],
  },
  106: {
    Id: "reciRiDXRlZ0dwKRd",
    Quiz: 106,
    Question:
      "L'utilisation de panneaux photovoltaïques pour absorber les rayons de soleil est une option intéressante pour la production ...",
    Theme: "Solaire",
    Explanation:
      "Le photovoltaïque (PV) est la transformation directe de l'énergie de rayonnement en énergie électrique au moyen de cellules solaires. Le principe physique sur lequel elle repose est l'effet photoélectrique. Le courant continu ainsi généré est généralement converti en courant alternatif par un onduleur et peut être consommé à proximité des panneaux photovoltaïque ou injecté au réseau électrique public si la production est supérieure à la consommation.",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/_F1_0884-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/LEJIJKublDCi98t9PYm2Hg/Kv0s3NB6d7i7QSTmQwcF6GAPN97rCqvuHDacyPdZoQLen5MJB8EB0vt6emsW76eadbFxYvVkfVx70g9Z9z8xMpm94SSMGOpna9HnLeY5jMbx_U6ixJIJwhiGiijatUnNnaQggQrdZWQC2RsYyXp86w/d9J_JD6w-GImFcDELqeRjehler_Nq8MdYRfBoPmfNmE",
    ],
  },
  107: {
    Id: "rec7LdTlFqeRQWwc8",
    Quiz: 107,
    Question:
      "Charade! Mon 1<sup>er</sup> est le masculin de fausse, mon 2<sup>e</sup> est le contraire de tard, mon 3<sup>e</sup> est l’une des unités de mesure de l’électricité, mon 4<sup>e</sup> est ce que l’on dit quand on se fait mal, mon 5<sup>e</sup> est le son du hoquet. Mon tout est...",
    Theme: "Solaire",
    Explanation:
      "Les panneaux photovoltaïques (faux-tôt-volt-aïe-hic) sont composés de cellules électroniques qui réagissent aux rayons du Soleil et qui permettent de produire de l’électricité. Ces installations sont de plus en plus répandues dans le monde. En Suisse romande, les parcs solaires photovoltaïques sont souvent installés sur les toits des bâtiments.",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/_F1_0884-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/LEJIJKublDCi98t9PYm2Hg/Kv0s3NB6d7i7QSTmQwcF6GAPN97rCqvuHDacyPdZoQLen5MJB8EB0vt6emsW76eadbFxYvVkfVx70g9Z9z8xMpm94SSMGOpna9HnLeY5jMbx_U6ixJIJwhiGiijatUnNnaQggQrdZWQC2RsYyXp86w/d9J_JD6w-GImFcDELqeRjehler_Nq8MdYRfBoPmfNmE",
    ],
  },
  108: {
    Id: "reca7bPQS4zor0X28",
    Quiz: 108,
    Question:
      "En moyenne, combien de m<sup>2</sup> de panneaux solaires thermiques bien orientés permettent de chauffer toute l'eau dont une personne a besoin?",
    Theme: "Solaire",
    Explanation:
      "L’été, 1 à 5 m<sup>2</sup> de panneaux solaires thermiques bien orientés suffisent pour chauffer toute l’eau dont une personne a besoin. Cela dépend bien sûr de l'utilisation d'eau!",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/_F1_0884-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/LEJIJKublDCi98t9PYm2Hg/Kv0s3NB6d7i7QSTmQwcF6GAPN97rCqvuHDacyPdZoQLen5MJB8EB0vt6emsW76eadbFxYvVkfVx70g9Z9z8xMpm94SSMGOpna9HnLeY5jMbx_U6ixJIJwhiGiijatUnNnaQggQrdZWQC2RsYyXp86w/d9J_JD6w-GImFcDELqeRjehler_Nq8MdYRfBoPmfNmE",
    ],
  },
  109: {
    Id: "rec89ZQ1taWTxCPJw",
    Quiz: 109,
    Question:
      "Quel pourcentage des besoins électriques annuels moyens d'un foyer de 4 personnes est couvert par 20 m<sup>2</sup> de panneaux photovoltaïques?",
    Theme: "Solaire",
    Explanation:
      "Selon SuisseEnergie, une installation solaire d’environ 20 m<sup>2</sup> de surface posée en toiture couvre 80% des besoins annuels en électricité d’une famille de 4 personnes (estimation pour une maison individuelle de taille moyenne).",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/_F1_0884-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/LEJIJKublDCi98t9PYm2Hg/Kv0s3NB6d7i7QSTmQwcF6GAPN97rCqvuHDacyPdZoQLen5MJB8EB0vt6emsW76eadbFxYvVkfVx70g9Z9z8xMpm94SSMGOpna9HnLeY5jMbx_U6ixJIJwhiGiijatUnNnaQggQrdZWQC2RsYyXp86w/d9J_JD6w-GImFcDELqeRjehler_Nq8MdYRfBoPmfNmE",
    ],
  },
  110: {
    Id: "reckI9QidBxjXDAun",
    Quiz: 110,
    Question: "Combien de types de panneaux solaires existent?",
    Theme: "Solaire",
    Explanation:
      "Il existe 2 principaux types de panneaux: les photovoltaïques, pour produire de l'électricité, et les thermiques pour le chauffage et l'eau sanitaire.",
    Images: [
      "assets/images/solar-1-1.jpg",
      "assets/images/solar-1-3.jpg",
      "assets/images/_F1_0884-web.jpg",
    ],
    ImagesFullAirtableUrl: [
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/i4wBSH7NFlkrJzM4iH95lA/WhuBrhlz9OKzMUFQnse2ffX72CNFUyaiwGmrKdsIKc7wTJqlf3lLoBrCtJJacuo8eb7LR3KUUaaT1ra-qqNUPKQHjuqSo3bUICiOymM89OFAQ1oUDDYiXg3FONAgBy9N6BvEO-ftaqROO3hfNd4ZGA/lNqNuc1t_nWOd3OnP8kucQntKgGPEZ3nJ2GyvMbmbBA",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/eVbkOsGJ9t6nzUQA63IrQw/17PHoi6VXOq41MgmGsz9lOMFaXR5oIahKOivgb3jGtbW8rbWvP4wunwaEikxyfu172y_TDfQ-jic-hhKJmXIF3QDOk14HYOyuDidAywt8yXAhxebwWyILnsVMng26HHeJZiMuc5D2S_VZ3efx8sJXw/j-CFqgz4CLL7QiMKFo6ri6uaUx4LU6w9gsTB7E8rmEQ",
      "https://v5.airtableusercontent.com/v2/23/23/1701864000000/LEJIJKublDCi98t9PYm2Hg/Kv0s3NB6d7i7QSTmQwcF6GAPN97rCqvuHDacyPdZoQLen5MJB8EB0vt6emsW76eadbFxYvVkfVx70g9Z9z8xMpm94SSMGOpna9HnLeY5jMbx_U6ixJIJwhiGiijatUnNnaQggQrdZWQC2RsYyXp86w/d9J_JD6w-GImFcDELqeRjehler_Nq8MdYRfBoPmfNmE",
    ],
  },
};
