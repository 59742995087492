var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field"},[_c('hi-label',{attrs:{"labelFor":"name"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{class:{
      control: true,
      'has-icons-left': _vm.icon,
      'has-icons-right': _vm.errors.has(_vm.name),
    }},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[_vm.name]),expression:"form[name]"}],class:{
        input: true,
        'is-danger': _vm.errors.has(_vm.name),
        'is-rounded': _vm.rounded,
      },attrs:{"id":_vm.name,"name":_vm.name,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form[_vm.name])?_vm._i(_vm.form[_vm.name],null)>-1:(_vm.form[_vm.name])},on:{"change":function($event){var $$a=_vm.form[_vm.name],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, _vm.name, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, _vm.name, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, _vm.name, $$c)}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[_vm.name]),expression:"form[name]"}],class:{
        input: true,
        'is-danger': _vm.errors.has(_vm.name),
        'is-rounded': _vm.rounded,
      },attrs:{"id":_vm.name,"name":_vm.name,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.form[_vm.name],null)},on:{"change":function($event){return _vm.$set(_vm.form, _vm.name, null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[_vm.name]),expression:"form[name]"}],class:{
        input: true,
        'is-danger': _vm.errors.has(_vm.name),
        'is-rounded': _vm.rounded,
      },attrs:{"id":_vm.name,"name":_vm.name,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.form[_vm.name])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, _vm.name, $event.target.value)}}}),(_vm.icon)?_c('span',{staticClass:"icon is-small is-left"},[_c('i',{class:_vm.icon})]):_vm._e(),(_vm.errors.has(_vm.name))?_c('span',{staticClass:"icon is-small is-right"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e(),_vm._t("default"),(_vm.errors.has(_vm.name))?_c('span',{staticClass:"help is-danger",domProps:{"textContent":_vm._s(_vm.errors.get(_vm.name))}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }