export default {
  methods: {
    next(quiz) {
      let session = this.$cookies.get("ee_session");
      session["Quiz"] = parseInt(quiz) + 1;
      session["Answered"] = 0;
      this.$cookies.set("ee_session", session);
    },
  },
};
