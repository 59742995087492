<template>
  <div class="content-wrapper">
    <div class="banner banner-up">
      <img class="ofi-image" src="/assets/static/banner_paysage.jpg" />
    </div>

    <div class="content">
      <h1>Merci d’avoir joué&nbsp;!</h1>
      <div class="score-wrapper">
        <span>Résultat&nbsp;:</span>
        <div class="h1-like">{{ score }}/10</div>
        <span>réponses justes</span>
      </div>
      <br>
      <p>
        Bravo&nbsp;! Tu veux améliorer ton score ?
      </p>

      <a href="/" @click="newQuiz" class="button button-text">LANCER UN NOUVEAU QUIZ</a>

      <a href="https://www.explorateurs-energie.ch/" class="button button-text">RETOUR À LA PAGE D'ACCUEIL</a>

    </div>

  </div>
</template>

<script>
import SessionAirtableMixin from "./../mixins/SessionAirtableMixin";
import bus from "./../bus";
import { Teams } from "./../data";

export default {
  mixins: [SessionAirtableMixin],

  data() {
    return {
      found: false,
      score: 0,
      fullname: "",
      session_id: "",
      team: "",
    };
  },
  methods: {
    newQuiz(e) {
      console.log("newQuiz")
      // prevent default
      e.preventDefault();
      bus.$emit("teamChanged", { team: "Grand Jeu des énergies", teamClass: "generic" });
      bus.$emit("themeChanged", { team: "", teamClass: "" });
      this.$router.push("/");
    },
  },
  mounted() {
    const session = this.$cookies.get("ee_session");
    const team = session["Team"];
    const teamClass = "team" + String(Teams.indexOf(team) + 1);

    this.score = session["Score"];
    this.fullname = session["Fullname"];
    this.team = team;
    bus.$emit("teamChanged", { team: team, teamClass: teamClass });

    bus.$emit("themeChanged", { theme: "Final", themeClass: "final" });

    const vm = this;
    if (!session.saved) {
      this.createSession().then(function () {
        session.saved = true;
        vm.$cookies.set("ee_session", session);
      });
    }
  },
};
</script>
